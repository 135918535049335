import { Dictionary, Installer, KnownDevice } from "@oneethos/shared"
import { BrowserFingerPrintData } from "../browser-fingerprint"

type DevicesProps = {
  installer: Installer & { devices: Dictionary<KnownDevice & { meta: BrowserFingerPrintData }> }
}

export const Devices = ({ installer }: DevicesProps) => {
  return <div className="devices">
    <h3>Authorized Devices</h3>
    <p>If you do not recognize any of these devices, please contact us immediately</p>
    <div className="device-list list-group">
      {Object.entries(installer.devices || {}).map(([uuid, device]) =>
        <div className="device list-group-item" key={uuid}>
          {typeof device === 'string' ? (<div>
            <div><b>id:</b> {uuid}</div>
            <div><b>User Agent:</b> {device}</div>
            <div><b>Last Login:</b> n/a</div>
          </div>) : <div>
            <div><b>id:</b> {uuid}</div>
            <div><b>Platform:</b> {device.meta?.platform}</div>
            <div><b>Timezone:</b> {device.meta?.timezone}</div>
            <div><b>User Agent:</b> {device.meta?.userAgent}</div>
            <div><b>Last Login:</b> {new Date(device.lastLogin).toLocaleString()}</div>
          </div>}
        </div>)}
    </div>
  </div>
}

export default Devices