import React, { useState } from 'react'
import { UploadSlot } from './upload-slot'
import { Spinner } from '@fluentui/react'
import { uploadSlotFiles } from '../../services/fileUploadService'
import { toast } from 'react-toastify'
import api from '../../api-client'
import { StatusData, RequiredDocConfig, errorToString } from '@oneethos/shared'

type AdditionalDocumentUploadProps = {
  project: StatusData
  onChange: (p: StatusData) => void
  uploadType: string
  slotConfig: RequiredDocConfig
}

export const AdditionalDocumentUpload = ({
  project,
  onChange,
  uploadType,
  slotConfig
}: AdditionalDocumentUploadProps) => {
  const [docs, setDocs] = useState([])
  const [notes, setNotes] = useState('')
  const [submitting, setSubmitting] = useState(false)

  if (submitting) {
    return <Spinner />
  }

  const onSubmit = () => {
    setSubmitting(true)
    uploadSlotFiles([[uploadType, docs[0]]]).then(documents => {
      return api.put(`/loanapps/${project.id}/docs/submit`, {
        uploadType: uploadType,
        documents,
        notes
      }).then(p => {
        const proj = new StatusData(p)
        proj.uiSort()
        toast.success('Document submitted successfully. You can view them in the History tab.')
        onChange(proj)
      })
    }).catch(ex => toast.error(errorToString(ex)))
    .finally(() => setSubmitting(false))
  }
  
  return (
  <div>
    <UploadSlot
      doc={docs[0]}
      slotConfig={slotConfig}
      onChange={ev => setDocs([ev.target.files[0]])}
      onRemove={() => setDocs([])}
    />
     <div className="form-group doc-upload-item">
       <div className="up-label">Please provide a detailed explanation of the uploaded documents:</div>
        <textarea
          className="form-control"
          value={notes}
          onChange={(ev) => setNotes(ev.target.value)} 
          maxLength={200}
        />
        <p className="small text-secondary">{notes.length} / 200 characters</p>
     </div>
     <div className="buttons form-group">
        <button type="button"
          className="btn btn-primary"
          disabled={false}
          onClick={onSubmit}
        >Submit</button>
     </div>
  </div>
  )
}

export default AdditionalDocumentUpload
