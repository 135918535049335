import { Document } from 'mongoose'

export interface Org extends Document {
  installerID: string
  active: boolean
  companyName: string
  operatingStates: string[]
  airtableId?: string
  achAccountNo?: string
  achRoutingNo?: string
  achInstitutionName?: string
  achAccountType?: string
}

// obviously needs to be a better place for this
export const isServiceableAddress = (state, country, org) => {
  return country === "United States" && (
    org.operatingStates?.includes(state) ||
    (!org.operatingStates?.length && state === 'FL')
  )
}

