
export const ApplicantRaceValues = [
  'Asian',
  'Black',
  'Hispanic',
  'Native Alaskan',
  'Native American',
  'Native Hawaiian',
  'Pacific Islander',
  'White',
  'Decline to Answer'
]

export type ApplicantRace = typeof ApplicantRaceValues[number]

