import { Spinner } from "@fluentui/react"
import { BrowserAgreement, DecisionProResponse, errorToString } from "@oneethos/shared"
import { useState } from "react"
import { getBrowserFingerprint, getBrowserFingerprintData } from "../../browser-fingerprint"
import { useApi } from "../../hooks"
import api from "../../api-client"
import { toast } from "react-toastify"
import { SubformProps } from "."

export const DecisionCopy = ({ formData }: Partial<SubformProps>) => {
  let payment = formData.submissionResponse?.success?.MonthlyPayment

  if (parseFloat(formData.incentive?.estPriceWithIncentives) > 0) {
    payment = formData.incentive.estPriceWithIncentives
  }

  if (formData.prequalResult === 'pass') {
    return <div className="alert alert-success">
      <div className="mb-3"><b>Congratulations!</b></div>
      <div className="mb-3">
        Your application has been pre-qualified pending document verification.
        A member of our banking team will reach out to you within 48 business hours.
      </div>
      {!formData.incentive && payment ? <div className="mb-3">
        Expected Monthly Payment: <b>${payment}</b>
      </div> : null}
    </div>
  } else {
    return <div className="alert alert-info">
      Your application has been received and is pending review. A member of our
      banking team will reach out to you within 48 business hours to discuss next steps.
    </div>
  }
}

export const Submitted = ({ formData, update }: SubformProps) => {
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(formData.preapprovalLetter?.agreement?.agrees === true)

  const { fetching, error, data } = useApi(`/c/loanapps/${formData._id}/prequalification-letter`)

  if (fetching) {
    return <Spinner />
  }

  const expired = error && typeof error !== 'string' && error.code === 'PREQUAL_EXPIRED'

  if (error && typeof error === 'string') {
    return <div className="alert alert-danger">{error}</div>
  } else if (error && !expired) {
    return <div className="alert alert-danger">
      {errorToString(error)}
    </div>
  }

  const agreement = formData.preapprovalLetter?.agreement

  return <div className="submitting">
    <h3>Thank you for submitting your application</h3>
    <DecisionCopy formData={formData} />
    {expired ? <div className="alert alert-info">
      Your preaqualification has expired; please contact the bank to discuss options.
    </div> : <>
      {formData.prequalResult === 'pass' ? <>
        <iframe
          src={data.url}
          title={`Prequalification Letter for ${formData._id}`}
          style={{
            width: '100%',
            height: '500px',
            border: 'none',
            marginTop: '20px'
          }}
        />

        {saving ? <Spinner style={{ padding: '2em' }} /> : (
          saved ? (
            <div className="alert alert-info mt-3">
              Thank you for acknowledging receipt of the prequalification letter. You should have
              received a copy via email, or you may download the copy above for your records.
            </div>
          ) : <div className={'form-group'} style={{ cursor: 'pointer', textAlign: 'left' }}>
            <label style={{ margin: '1em 0' }}>
              <input type="checkbox"
                onChange={ev => {
                  if (ev.target.checked) {
                    update({
                      ...formData,
                      preapprovalLetter: {
                        ...formData.preapprovalLetter,
                        agreement: {
                          browser: getBrowserFingerprintData(),
                          timestamp: new Date(),
                          agrees: ev.target.checked
                        }
                      }
                    })
                  } else {
                    update({
                      ...formData,
                      preapprovalLetter: {
                        ...formData.preapprovalLetter,
                        agreement: undefined
                      }
                    })
                  }
                }}
                checked={agreement?.agrees === true}
              />
              &nbsp;
              I acknowledge that I have reviewed the above pre-qualification letter; by clicking
              "Accept", it will record my acknowledgement and email me a copy of this agreement.
            </label>
            <button type="button"
              className="btn btn-primary"
              disabled={agreement?.agrees !== true}
              onClick={() => {
                setSaving(true)
                api.patch(`/c/loanapps/${formData._id}/prequalification-letter`, {
                  contentMD5: data.contentMD5,
                  agreement
                }).then(res => { setSaved(true) })
                  .catch(ex => toast.error(errorToString(ex)))
                  .finally(() => { setSaving(false) })
              }}>Accept</button>
          </div>
        )}
      </> : null}
    </>}
  </div>
}

