import { InstallerStatusEditor, Section, TenantSettings } from "../components"

export const BankerDashboardPage = () => {
  return <div className="page">
    <Section>
      <TenantSettings />
    </Section>
    <Section>
      <InstallerStatusEditor />
    </Section>
  </div>
}

export default BankerDashboardPage