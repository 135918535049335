import { LoanDocument, RequiredDocConfig, StatusData, UploadDocType } from "@oneethos/shared"
import { useEffect, useState } from "react"
import api from '../../api-client'
import { toast } from "react-toastify"
import { FaCheck } from "react-icons/fa"
import Comment from "../comment"
import { DocLink } from "./doc-link"

type DocActionProps = {
  doc: LoanDocument
  commenting: boolean
  onComment: () => void
  onSave: (d: Partial<LoanDocument>) => void
  onSaveComments: () => void
}

export const DocAction = ({ doc, onComment, onSave, onSaveComments, commenting }: DocActionProps) => {
  if (doc.status === 'approved') {
    return <div style={{ alignSelf: 'flex-start' }}>
      <span className="badge rounded-pill text-bg-success">
        Approved&nbsp;<FaCheck color='white' title="approved" />
      </span>
      {/* Hopefully this is not necessary and things are final once approved */}
      {/* <FaTimesCircle color='#aaa' /> */}
    </div>
  }

  if (commenting) {
    return <div className="doc-action btn-group">
      <button type="button"
        onClick={() => onSaveComments()}
        className="btn btn-sm btn-primary">Save</button>
      <button type="button" className="btn btn-sm btn-danger" onClick={onComment}>Cancel</button>
    </div>
  }

  if (doc.status === 'accepted') {
    return <div className="doc-action btn-group">
      <button type="button"
        className="btn btn-sm btn-success"
        onClick={() => onSave({ status: 'approved' })}
      >Approve</button>
      <button type="button" className="btn btn-sm btn-warning" onClick={onComment}>Comment</button>
    </div>
  }

  if (doc.status === 'comments') {
    return <Comment text={doc.comments} />
  }

  if (doc.status === 'review') {
    return <div className="doc-action btn-group">
      <button type="button"
        className="btn btn-sm btn-alt"
        onClick={() => onSave({ status: 'accepted' })}
      >Accept</button>
      <button type="button" className="btn btn-sm btn-warning" onClick={onComment}>Comment</button>
    </div>
  }

  return <div className="doc-action btn-group">
    <button type="button" className="btn btn-sm btn-primary"
      onClick={() => onSave({ status: 'review' })}
    >Review</button>
  </div>
}

type BankerDocumentSlotProps = {
  project: StatusData
  document: LoanDocument
  slotConfig: RequiredDocConfig,
  uploadType: UploadDocType,
  onChange?: (p: StatusData) => void
}

export const BankerDocumentSlot = ({
  project,
  document,
  slotConfig,
  uploadType,
  onChange
}: BankerDocumentSlotProps) => {
  const [commenting, setCommenting] = useState(false)
  const [doc, setDoc] = useState(document)
  const path = `/loanapps/${project.id}/docs/${encodeURIComponent(uploadType)}/${encodeURIComponent(slotConfig.slot)}`

  useEffect(() => {
    setDoc(document)
  }, [document])

  const save = (data: Partial<LoanDocument>) => {
    api.patch(path, data)
      .then(_proj => {
        const proj = new StatusData(_proj)
        proj.uiSort()
        onChange(proj)
      })
      .catch(ex => toast.error(ex.error, { autoClose: false }))
  }

  return <div className="doc">
    <div className="doc-main">
      <DocLink doc={doc} slotConfig={slotConfig} uploadType={uploadType} project={project} />
      {doc?.blobName ? (
        <DocAction
          doc={doc}
          onSave={save}
          commenting={commenting}
          onSaveComments={() => {
            save({ status: 'comments', comments: doc.comments })
            setCommenting(false)
          }}
          onComment={() => setCommenting(!commenting)}
        />
      ) : <span style={{ fontSize: '0.8em', color: '#aaa' }}>Not Uploaded</span>}
    </div>
    {commenting ? <textarea
      className="form-control"
      onChange={ev => setDoc({ ...doc, comments: ev.target.value })}
      value={doc.comments}
    /> : null}
  </div>
}

export default BankerDocumentSlot