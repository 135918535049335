import React, { useContext, useEffect, useState } from 'react'
import HeaderLogo from "../components/header-logo"
import { Spinner } from '@fluentui/react'
import { useLocalStorage, useUtmQuery } from '../hooks'
import jwtDecode from 'jwt-decode'
import api from '../api-client'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import actions from '../actions'
import InstallerService from '../services/installerService'
import { AppContext } from '../appContext'
import { Section } from '../components'
import { StorageKey } from '../lib'
import { errorToString } from '@oneethos/shared'
import { Navigate, useSearchParams } from 'react-router-dom'

type VerificationTokenPayload = {
  _id: string
  email: string
  // campaign?: string
  // installerID?: string
  // branchId?: string
}

type VerifyInformationProps = {
  verificationToken?: string
  securityCode?: string
}

const LoginWithThisBrowser = () => {
  const navigate = useNavigate()

  return <div className="buttons" >
    <button type="button" className="btn btn-primary"
      onClick={() => {
        navigate(`/login`)
      }}
    >Login with this Browser</button>
  </div>
}

export const VerifyInformation = ({ verificationToken, securityCode }: VerifyInformationProps) => {
  const { state: { registration: { installer } }, dispatch } = useContext(AppContext)
  const [token, setToken, clearToken] = useLocalStorage(StorageKey.InstallerToken, undefined)
  const [attemptId, setAttemptId, clearAttemptId] = useLocalStorage(StorageKey.LoginAttemptId, undefined)
  const initialStep = (verificationToken || securityCode) ? 'VERIFYING_EMAIL' : 'AWAITING_SECURITY_CODE'
  const [step, setStep] = useState(initialStep)
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const [code, setCode] = useState('')
  const [emailVerificationCode, setEmailVerificationCode] = useState('')
  const { route } = useUtmQuery()
  const navigate = useNavigate()

  const handleError = (ex: any) => {
    if (ex.error === 'jwt expired') {
      setStep('')
      setError('This link has expired. Please restart the registration process.')
    } else {
      setError(errorToString(ex))
      setStep(_step => {
        switch (_step) {
          // put back to previous step
          case 'VERIFYING_EMAIL': return 'AWAITING_SECURITY_CODE'
          case 'SENDING_CODE': return 'PROMPT_FOR_SMS'
          case 'VERIFYING_CODE': return 'READY_FOR_CODE'
          default: return ''
        }
      })
    }
  }

  useEffect(() => {
    if (verificationToken || (securityCode && attemptId)) {
      const search = new URLSearchParams(window.location.search)
      search.append('la', attemptId)

      api.get(`/installers/verify-email?${search.toString()}`).then(res => {
        setPhone(res.phone)
        setStep('PROMPT_FOR_SMS')
      }).catch(ex => handleError(ex))
    }
  }, [verificationToken, securityCode, attemptId])

  useEffect(() => {
    if (code.length === 4) {
      setError('')
      setStep('VERIFYING_CODE')
      api.post(`/installers/verify-code`, {
        code,
        token: verificationToken,
        phone,
        la: attemptId
      }).then(res => {
        setToken(res.token)
        dispatch(actions.setInstallerToken(res.token))
        dispatch(actions.setInstaller(res.installer))
        clearAttemptId()
        setStep('SUCCESS')
      }).catch(ex => handleError(ex))
    }
  }, [code, verificationToken, phone, dispatch])

  useEffect(() => {
    if (emailVerificationCode.length >= 6) {
      setStep('VERIFYING_EMAIL')
      const query = new URLSearchParams({ code: emailVerificationCode, la: attemptId })
      api.get(`/installers/verify-email?${query.toString()}`).then(res => {
        setPhone(res.phone)
        setStep('PROMPT_FOR_SMS')
      }).catch(ex => handleError(ex))
    }
  }, [emailVerificationCode])

  if (installer && !['SUCCESS', 'VERIFYING_CODE'].includes(step)) {
    return <Navigate to={route || `/projects`} />
  }

  if (!token && !verificationToken && !attemptId) {
    return <div>
      <div className="alert alert-warning">
        <p><b>Trying to register or login?</b></p>
        <p>To protect your security, we require that you login using the same browser that was used to initiate the login request.</p>
        <p>However, there is no active login attempt on this browser - please double check or restart the login process.</p>
      </div>
      <LoginWithThisBrowser />
    </div>
  }

  if (error) {
    return <div>
      <div className="alert alert-danger">{error}</div>
      <LoginWithThisBrowser />
    </div>
  }

  return <div className="installer-verification">
    {step === 'AWAITING_SECURITY_CODE' ? <div>
      <p>A security code has been sent to your email address. Please enter it below.</p>
      <div className="form-inline">
        <div className="form-group ready-for-code">
          <input
            type="text"
            maxLength={6}
            className="form-control"
            value={emailVerificationCode}
            autoFocus
            onChange={ev => {
              setError('')
              setEmailVerificationCode(ev.target.value.replace(/\D/g, ''))
            }}
          />
        </div>
      </div>
    </div> : <></>}
    {step === 'VERIFYING_EMAIL' ? <div>
      <Spinner />
      <p>Verifying Email...</p>
    </div> : <></>}
    {step === 'PROMPT_FOR_SMS' ? <div className="sms-prompt">
      <div className="alert alert-success">Email address successfully verified!</div>
      <div>
        <p>
          Last step - we need send you a text message to
          verify your phone number. SMS rates may apply.
        </p>
        <p>Is <b>{phone}</b> the correct phone number?</p>
        <div className="buttons">
          <button type="button" className="btn btn-primary"
            onClick={() => {
              setStep('SENDING_CODE')
              // bypass if there are issues sending SMS
              // setTimeout(() => setStep('READY_FOR_CODE'), 2000)
              api.post('/installers/send-sms', {
                phone,
                token: verificationToken,
                la: attemptId
              }).then(res => {
                setStep('READY_FOR_CODE')
              }).catch(ex => handleError(ex))
            }}
          >Yes, send code to {phone}</button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              navigate(`/profile`)
            }}
          >This phone # is incorrect</button>
        </div>
      </div>
    </div> : <></>}
    {step === 'SENDING_CODE' ? <div>
      <Spinner />
      <p>Sending Code via SMS...</p>
    </div> : <></>}
    {step === 'READY_FOR_CODE' ? <div className="ready-for-code">
      <p>We sent a code to {phone}. Enter the code below to complete registration.</p>
      <div className="form-inline">
        <div className="form-group">
          <input
            type="text"
            maxLength={4}
            className="form-control"
            value={code}
            autoFocus
            onChange={ev => {
              setError('')
              setCode(ev.target.value.replace(/\D/g, ''))
            }}
          />
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          setError('')
          setCode('')
          setStep('PROMPT_FOR_SMS')
        }}
      >Resend Code</button>
    </div> : <></>}
    {step === 'VERIFYING_CODE' ? <div>
      <Spinner />
      <p>Verifying code...</p>
    </div> : <></>}
    {step === 'SUCCESS' ? <div>
      <div className="alert alert-success">Successfully verified information!</div>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => {
          navigate(route || `/projects`)
        }}
      >Continue</button>
    </div> : <></>}
  </div>
}

const CheckLoginState = () => {
  const { state: { registration: { installer } }, dispatch } = useContext(AppContext)
  const { t, route, code } = useUtmQuery()

  if (t) {
    try {
      const payload = jwtDecode(t) as VerificationTokenPayload
      const { _id } = payload

      if (_id && installer?._id === _id) {
        if (installer.access === 'consumer') {
          return <Navigate to={route || `/applications`} />
        } else {
          return <Navigate to={route || `/projects`} />
        }
      }
    } catch (ex) {
      dispatch(actions.setAuthError(errorToString(ex)))
      return <Navigate to={`/login`} />
    }
  }

  return <VerifyInformation
    verificationToken={t}
    securityCode={code}
  />
}

export const VerifyEmailPage = () => {
  return <Section>
    <CheckLoginState />
  </Section>
}

export default VerifyEmailPage
