
type LinkProps = {
  href: string
  label: string
  target?: string
  style?: React.CSSProperties
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Link = ({ href, label, style, target, onClick }: LinkProps) => {
  return <div className="link link-wrap" style={style}>
    <a href={href} target={target} onClick={onClick}>{label}</a>
  </div>
}