import React, { useEffect, useState } from 'react'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import {
  Section,
  ProjectListItem,
  NavOpt
} from '../components'
import { useAppState, useInstallerMap } from '../hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { StatusData } from '@oneethos/shared'

export const ProjectPage = () => {
  const { id } = useParams()
  const [search, setSearchParams] = useSearchParams()
  const [project, setProject] = useState<Partial<StatusData>>({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const { installersMap, imapError } = useInstallerMap()
  const { installersStatusConfig: isc } = useAppState()

  useEffect(() => {
    api.getProject(id).then(item => {      
      setProject(item)
    }).catch(ex => setError(ex.error || JSON.stringify(ex)))
    .finally(() => setLoading(false))
  }, [id])

  if (loading || isc.loading) {
    return <Spinner />
  }

  const v = search.get('v')
  const nav = ['history', 'other', 'checklist'].includes(v) ? v : null

  if (error) {
    return <Section>
      <div className="alert alert-danger">
        <div>An error occurred while loading the project.</div>
        <div><b>Error:</b> {error}</div>        
      </div>
    </Section>
  }

  return <>
    <Section>
      <div className="list-group">
        <ProjectListItem
          project={project as StatusData}
          installersMap={installersMap}
          onChange={p => setProject(p)}
          initialNav={nav as NavOpt}
          autoExpandIfActionNeeded
        />
      </div>
    </Section>
  </>
}

export default ProjectPage
