import React, { useState } from 'react'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import {
  Section,
  MiniSection,
} from '../components'
import { useAppState, useUtmQuery } from '../hooks'
import { ProjectList } from '../components/project-list'

const RequestUpdate = () => {
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [email, setEmail] = useState('')
  const { registration: { installer } } = useAppState()
  const utm = useUtmQuery()

  return <Section>
    <div className="section-title">
      <h2 className="margin-bottom-0">Active Projects - Request Project Updates</h2>
    </div>
    <div className="w-form">
      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
        {/* If user is registered, we don't prompt for email because we already know it */}
        {installer ? null : <div className="form-group">
          <label className="text-block-9">Enter your email address</label>
          <input type="email"
            className="form-control"
            maxLength={256}
            placeholder="installer@email.com"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
            autoComplete="off" />
        </div>}
        {sending ? <Spinner /> : <div className="buttons">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              // status request supports manually entering an email or using the token
              setSending(true)
              api.post(`/loanapps/status`, {
                ...utm,
                email
              }).then(res => {
                setSuccess([
                  "Thank you for submitting your request. An email will be sent to you shortly.",
                  "If you dont receive an email, please contact your administrator at your company to request that you are added to the update list."
                ].join(' '))
              }).catch(ex => {
                // console.log(ex)
                setError(ex.error || ex.message)
              }).finally(() => setSending(false))
            }}
          >Request Project Update</button>
        </div>}
      </form>
      {success ? <div className="div-block">{success}</div> : null}
      {error ? <div className="div-block" style={{ color: 'red' }}>{error}</div> : null}
    </div>
  </Section>
}

const MiniPrompt = () => {
  return <MiniSection>
    Complete your registration to manage communication preferences and view project status list.
  </MiniSection>
}

export const InstallerProjectsPage = () => {
  const { registration: { installer } } = useAppState()

  return <>
    {!installer ? <MiniPrompt /> : <ProjectList />}
    <RequestUpdate />
  </>
}

export default InstallerProjectsPage
