import { Installer, InstallerRegistrationPayload } from "@oneethos/shared"
import { Reducer } from "react"

export interface InstallerRegistration {
  loading: boolean
  token?: string | null
  installer?: InstallerRegistrationPayload
  deadline?: Date
  authError?: string
}

export type InstallerActionTypes =
  'LOGOUT' |
  'SET_AUTH_ERROR' |
  'SET_INSTALLER_LOADING' |
  'SET_INSTALLER_TOKEN' | 
  'SET_INSTALLER' | 
  'SET_REGISTRATION_DEADLINE'

export type InstallerAction = {
  type: InstallerActionTypes
  data?: any
}

export const registrationReducer: Reducer<InstallerRegistration, InstallerAction> = (
  state: InstallerRegistration,
  action: InstallerAction
): InstallerRegistration => {
  switch (action.type) {
    case 'SET_INSTALLER_LOADING':
      state = {
        ...state,
        loading: true,
      }
      break
    case 'LOGOUT':
      state = {
        ...state,
        loading: false,
        token: undefined,
        installer: undefined
      }
      break
    case 'SET_INSTALLER':
      state = {
        ...state,
        loading: false,
        installer: action.data,
      }
      break
    case 'SET_INSTALLER_TOKEN':
      state = {
        ...state,
        token: action.data
      }
      break
    case 'SET_REGISTRATION_DEADLINE':
      state = {
        ...state,
        deadline: action.data
      }
      break
    case 'SET_AUTH_ERROR':
      state = {
        ...state,
        authError: action.data,
        loading: false
      }
      break
  }

  return state
}