import react from 'react'
import { useApi } from "../hooks"
import TenantForm from './tenant-form'

export const TenantSettings = () => {
  const { fetching, data, error } = useApi('/tenant')

  if (fetching) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>
  }

  return <div className="tenant-settings">
    <h4>Tenant Settings</h4>
    <TenantForm initialConfig={data} />
  </div>
}

export default TenantSettings