
export interface IStreetAddress {
  streetAddress?: string
  streetAddressLine2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export class StreetAddress {
  streetAddress?: string
  streetAddressLine2?: string
  city?: string
  state?: string
  zip?: string
  country?: string

  constructor(a: IStreetAddress) {
    this.streetAddress = a.streetAddress
    this.streetAddressLine2 = a.streetAddressLine2
    this.city = a.city
    this.state = a.state
    this.zip = a.zip
    this.country = a.country
  }

  static isValid(a: StreetAddress): boolean {
    return a && !!(
      a.streetAddress &&
      a.city &&
      a.state &&
      a.zip &&
      a.country
    )
  }

  static toString(a: StreetAddress): string {
    if (StreetAddress.isValid(a)) {
      const line2 = a.streetAddressLine2 ? ' ' + a.streetAddressLine2 : ''
      return `${a.streetAddress}${line2}, ${a.city}, ${a.state} ${a.zip}`
    } else {
      return '[invalid street address]'
    }
  }

  static line1(a: StreetAddress): string {
    return a.streetAddress || ''
  }

  static cityStZip(a: StreetAddress): string {
    return `${a.city}, ${a.state} ${a.zip}`
  }
}


