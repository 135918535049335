
// for status email
export const PreSubmitStatusOrder = [
  'Loan Request',
  'Eligibility',
  'Contact Information',
  'Employment',
  'Co-Borrower',
  'Income Verification',
  'Auto-Debit Authorization',
  'Submit Application',
  'Submitting',
  'Submitted'
]

export type PreSubmitStatus = typeof PreSubmitStatusOrder[number]

export const PreSubmitUtils = {
  nextStatus(status: PreSubmitStatus): PreSubmitStatus {
    const idx = PreSubmitStatusOrder.findIndex(s => s === status)
    return PreSubmitStatusOrder[idx + 1]
  },
  isAfter(ref: PreSubmitStatus, check: PreSubmitStatus): boolean {
    const ridx = PreSubmitStatusOrder.findIndex(s => s === ref)
    const cidx = PreSubmitStatusOrder.findIndex(s => s === check)
    return cidx > ridx
  }
}