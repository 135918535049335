import { Dictionary, LoanDocument, StatusData, UploadDocType } from "@oneethos/shared"
import { useEffect, useState } from "react"
import { useAppState } from "../../hooks"
import { uploadSlotFiles } from "../../services/fileUploadService"
import api from '../../api-client'
import { toast } from "react-toastify"
import { Spinner } from "@fluentui/react"
import { UploadSlot } from "./upload-slot"
import { DocActionBadge } from "../doc-action-badge"

type FormState = {
  uploadType: UploadDocType
  stepDocs: Dictionary<LoanDocument & File>
}

export const InstallerUploadForm = ({ project, onProjectChange }) => {
  const [submitting, setSubmitting] = useState(false)
  const { installersStatusConfig: isc } = useAppState()
  const step = isc.dict[project.installersStatus]
  const [formState, setFormState] = useState<FormState>({
    uploadType: step.uploadType,
    stepDocs: project.installerDocs[step.uploadType] || {}
  })

  useEffect(() => {
    const _step = isc.dict[project.installersStatus]

    // this sequencing finds docs uploaded to a slot regardless of which step it was uploaded
    const projectDocs = {}
    Object.values(project.installerDocs).forEach(docMap => {
      Object.assign(projectDocs, docMap)
    })

    const stepDocs = {}
    _step.requiredDocuments.forEach(reqDoc => {
      stepDocs[reqDoc.slot] = projectDocs[reqDoc.slot]
    })

    setFormState({
      uploadType: _step.uploadType,
      stepDocs
    })
  }, [project, isc])

  const onSubmit = () => {
    setSubmitting(true)

    const docs = Object.entries(formState.stepDocs).filter(([, file]) => file && !file.status)
    uploadSlotFiles(docs).then(documents => {
      return api.put(`/loanapps/${project.id}/docs/submit`, {
        ...formState,
        documents
      }).then(proj => {
        const p = new StatusData(proj)
        p.uiSort()
        onProjectChange(p)
        toast.success('Documents uploaded successfully 🎉')
      })
    }).catch(ex => {
      const err = ex.error || ex.message || JSON.stringify(ex)
      toast.error(err, { autoClose: false })
    }).finally(() => setSubmitting(false))
  }

  const docs = Object.values(formState.stepDocs)
  const addedDocsCount = docs.filter(d => d && !d?.status).length
  const totalAddedDocs = docs.reduce((prev, cur) => prev + (cur && cur?.status !== 'comments' ? 1 : 0), 0)

  return step.uploadType ? <div className="project-docs">
    <div style={{ fontSize: '18px', marginBottom: '0.7em' }}>
      <DocActionBadge count={project.docsNeedInstallerActionCount} />
    </div>
    <div>{step.requiredDocuments.map(reqDoc => (
      <UploadSlot
        key={reqDoc.slot}
        doc={formState.stepDocs[reqDoc.slot]}
        slotConfig={reqDoc}
        onRemove={() => {
          const stepDocs = { ...formState.stepDocs }
          delete stepDocs[reqDoc.slot]
          setFormState({ ...formState, stepDocs })
        }}
        onChange={ev => setFormState({
          ...formState,
          stepDocs: {
            ...formState.stepDocs,
            [reqDoc.slot]: ev.target.files[0]
          }
        })}
      />
    ))}</div>
    <div className="alert alert-info">
      <div style={{ fontWeight: 'bold' }}>
        <div>
          {totalAddedDocs} / {Object.keys(project.requiredDocuments).length}
          &nbsp;documents added for this step.
        </div>
      </div>
      <div>
        We review documents in the order they are received. Once
        all documents are uploaded for a step, they are submitted to finance for approval.
      </div>
    </div>
    {addedDocsCount ? <div className="form-group">
      <button type="button"
        className="btn btn-primary"
        disabled={addedDocsCount === 0 || submitting}
        onClick={onSubmit}>{submitting ? <Spinner /> : "Save Documents"}</button>
    </div> : null}
  </div> : <div className="alert alert-info">
    No documents required for this step
  </div>
}

export default InstallerUploadForm
