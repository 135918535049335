import { ContainerClient } from "@azure/storage-blob"
import { LoanDocument, UploadedDoc } from "@oneethos/shared"
import api from '../api-client'

export const uploadFile = async (file: File): Promise<UploadedDoc> => {
  const query = new URLSearchParams({
    name: file.name,
    type: file.type,
  }).toString()

  const { url, blobName, containerName } = await api.get(`/docs/sign-upload?${query}`)
  const containerClient = new ContainerClient(url)
  const blockBlobClient = containerClient.getBlockBlobClient(blobName)
  await blockBlobClient.uploadBrowserData(file, { blobHTTPHeaders: { blobContentType: file.type } })
  return { containerName, blobName, label: file.name, contentType: file.type }
}

// for installers slot-based workflow
export const uploadSlotFiles = (docs: [s: string, f: File & LoanDocument][]): Promise<UploadedDoc[]> => {
  return Promise.all(docs.map(async ([docType, file]) => {
    const { containerName, blobName } = await uploadFile(file)
    return { containerName, blobName, label: file.name, docType, contentType: file.type }
  }))
}

export const fileToObj = (f: File) => {
  // needed because File objects don't serialize as might be expected
  return {
    name: f.name,
    size: f.size,
    type: f.type
  }
}