import { Dictionary, Installer, InstallerStatus } from "@oneethos/shared"
import { Reducer } from "react"

export interface InstallersStatusConfig {
  loading: boolean
  error?: string
  list?: InstallerStatus[]
  dict?: Dictionary<InstallerStatus>
}

export type InstallersStatusConfigActionTypes =
  'LOAD_INSTALLERS_STATUS_CONFIG' |
  'SET_INSTALLERS_STATUS_CONFIG' |
  'SET_INSTALLERS_STATUS_CONFIG_ERROR'

export type InstallersStatusConfigAction = {
  type: InstallersStatusConfigActionTypes
  data?: any
}

export const installersStatusReducer: Reducer<
  InstallersStatusConfig,
  InstallersStatusConfigAction
> = (
  state: InstallersStatusConfig,
  action: InstallersStatusConfigAction
): InstallersStatusConfig => {
  switch (action.type) {
    case 'LOAD_INSTALLERS_STATUS_CONFIG':
      state = {
        ...state,
        loading: true,
      }
      break
    case 'SET_INSTALLERS_STATUS_CONFIG':
      state = {
        ...state,
        loading: false,
        ...action.data
      }
      break
    case 'SET_INSTALLERS_STATUS_CONFIG_ERROR':
      state = {
        ...state,
        error: action.data
      }
      break
  }

  return state
}