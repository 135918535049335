import React from 'react'
import {
  Section,
  MiniSection,
  InstallerProfile,
  CommunicationPreferences
} from '../components'
import { useAppState } from '../hooks'
import { Devices } from '../components/devices'

export const InstallerProfilePage = () => {
  const { registration: { installer } } = useAppState()

  return <>
    <InstallerProfile />
    {installer && !installer.isConsumer ? <Section>
      <CommunicationPreferences />
    </Section> : null}
    {installer ? <Section>
      <Devices installer={installer} />
    </Section> : null}
  </>
}

export default InstallerProfilePage
