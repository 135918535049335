import { LoanDocument, RequiredDocConfig } from "@oneethos/shared"
import { ChangeEventHandler, useState } from "react"
import { FaFileUpload, FaRegFile, FaTimesCircle } from "react-icons/fa"
import { FaFileCircleCheck } from "react-icons/fa6"
import Comment from "../comment"
import byteSize from 'byte-size'

type BSVariant = 'primary' | 'success' | 'info' | 'warn' | 'danger' | 'secondary' | 'light'

type BadgeProps = {
  variant: BSVariant
  pill?: boolean
  outline?: boolean
  children
}

const Badge = ({
  variant = "primary",
  outline = false,
  pill = true,
  children
}: BadgeProps) => (
  <span className={[
    "badge",
    `text-${outline ? '' : 'bg-'}${variant}`,
    pill ? "rounded-pill" : '',
    outline ? `border border-${variant}` : ''
  ].join(' ')}>{children}</span>
)

const DocStatus = ({ doc }: { doc: LoanDocument }) => {
  return <div className={`doc-status ${doc.status}`}>
    <div className="file-label text-secondary"><FaRegFile /> {doc.label}</div>
    {doc.status === 'submitted' ? <Badge variant="primary">Submitted</Badge> : null}
    {doc.status === 'approved' ? <Badge variant="success">Approved</Badge> : null}
    {doc.status === 'review' ? <Badge variant="info">In Review</Badge> : null}
    {doc.status === 'accepted' ? <Badge variant="primary" outline>Pending Final Approval</Badge> : null}
    {doc.status === 'comments' ? <Comment text={doc.comments} /> : null}
  </div>
}

type UploadSlotProps = {
  doc?: LoanDocument & File
  multiple?: boolean
  slotConfig: RequiredDocConfig
  onChange: ChangeEventHandler<HTMLInputElement>
  onRemove: () => void
}

export const UploadSlot = ({
  doc,
  multiple = false,
  slotConfig,
  onChange,
  onRemove
}: UploadSlotProps) => {
  const [inputId,] = useState(crypto.randomUUID())

  return <div className="form-group doc-upload-item">
    <div className="up-label">{slotConfig.slot}</div>
    {doc?.status ? <DocStatus doc={doc} /> : null}
    {!doc || !doc.status || doc.status === 'comments' ? <>
      <div className="instructions">{slotConfig.instructions}</div>
      <label htmlFor={`file_input_${inputId}`} className="upload-prompt">
        {/* 
          it's possible that abrigo document sync results in doc === {} even if no action has 
          ever been taken for that doc; accordingly, we treat {} as an empty slot, but treat 
          status === 'comments' as a slot that has been interacted with, and prompt for new doc
        */}
        {doc && doc.name && doc.status !== 'comments' ? <div className="btn-group">
          <div className="btn btn-success btn-sm">
            <FaFileCircleCheck color="white" width="1em" />
            <div className="file-name">{doc.name}</div>
            <div className="file-size">
              {`(${byteSize(doc.size)})`}
            </div>
          </div>
          <div
            className="btn btn-secondary detach btn-sm"
            onClick={ev => {
              // prevent the upload dialog from showing
              ev.preventDefault()
              onRemove()
            }}>
            <FaTimesCircle color="white" width="1em" />
          </div>
        </div> : <div className="btn btn-secondary btn-sm">
          <FaFileUpload color="white" />
          <span>Choose File...</span>
        </div>}
      </label>
      <input
        id={`file_input_${inputId}`}
        type="file"
        // multiple={multiple}
        style={{ display: 'none' }}
        onChange={onChange}
      />
    </> : null}
  </div>
}
