import { useState } from "react"
import { MultiSelect } from "./forms"
import { Dictionary, Installer, StatusData } from "@oneethos/shared"

type AssigneesProps = {
  project: StatusData
  installersMap: Dictionary<Installer>
  onChange: (p: StatusData) => void
  onDoneEditing?: () => void
}

export const Assignees = ({
  project,
  onDoneEditing,
  onChange,
  installersMap
}: AssigneesProps) => {
  const [editingAssignees, setEditingAssignees] = useState(false)

  const authorizedOptions = {}
  Object.entries(installersMap).forEach(([id, installer]) => authorizedOptions[id] = installer.email)

  return <div className="assignees">
    <div>Solar Representative</div>
    {editingAssignees ? (
      <>
        <div style={{ margin: '1em 0' }}>
          <MultiSelect
            options={authorizedOptions}
            values={project.authorizedInstallers || []}
            onChange={vals => onChange({
              ...project,
              authorizedInstallers: vals
            })}
            wrapStyle={{ maxHeight: '26em', overflowY: 'auto' }}
          />
        </div>
        <div>
          <button
            onClick={() => {
              setEditingAssignees(false)
              onDoneEditing()
            }}
            className="btn btn-dark"
            type="button">Done</button>
        </div>
      </>
    ) : <div className="ass-list my-1">{
      (project.authorizedInstallers || []).map(id => {
        const inactive = installersMap[id]?.active === false ? 'inactive' : ''
        return <span className={`ass me-1 mb-1 px-2 py-1 ${inactive}`} title={inactive} key={id}>
          {installersMap[id]?.email}
        </span>
      })}
      {!project.authorizedInstallers?.length ? <span style={{ color: '#aaa', marginRight: '0.5em' }}>(Unassigned)</span> : null}
      <span className="link-primary mb-1" onClick={() => setEditingAssignees(true)}>edit</span>
    </div>}
  </div>
}