import { TenantPlaidConfig } from "@oneethos/shared"
import { PasswordInput, TextInput } from "../forms"

export const PlaidForm = ({ config, setConfig }) => {
  const setPlaidConfig = (c: Partial<TenantPlaidConfig>) => {
    setConfig({ ...config, plaid: { ...config.plaid, ...c } })
  }

  return <div>
    <h4>Plaid Configuration</h4>
    <p>To disable plaid, remove either the clientId or secret</p>
    <div className="form-group">
      <label>Plaid Environment</label>
      <select className="form-select"
        value={config?.plaid?.env || ''}
        onChange={ev => setPlaidConfig({ env: ev.target.value })}
      >
        <option value="">(select...)</option>
        <option value="sandbox">sandbox</option>
        <option value="production">production</option>
      </select>
    </div>

    <div className="form-group">
      <label>Client ID</label>
      <TextInput
        value={config?.plaid?.clientId || ''}
        onChange={val => setPlaidConfig({ clientId: val })}
      />
    </div>

    <div className="form-group">
      <label>Client Secret</label>
      <PasswordInput
        value={config?.plaid?.secret}
        onChange={val => setPlaidConfig({ secret: val })}
      />
    </div>

  </div>
}