import { MultiFileUpload } from '../../components/forms'

const DocSelector = ({ docType, onDocTypeChange, onChange, docs }) => {
  const numRequired = docType === 'paystubs' ? 2 : 1

  return <div className="form-group mb-5">
    <label>Select Documentation Type</label>
    <select value={docType}
      className="form-select"
      onChange={onDocTypeChange}
    >
      <option value="">(make selection)</option>
      <option value="W-2">W-2</option>
      <option value="paystubs">Paystubs</option>
      <option value="taxreturn">Tax Return</option>
      <option value="k1">K1</option>
      <option value="tax1099">1099</option>
      <option value="retirementBenefitLetter">Retirement Benefit Letter</option>
      <option value="vaDisabilityLetter">VA Disability Letter</option>
      <option value="socialSecurityAwardLetter">Social Security Award Letter</option>
    </select>

    <div className="my-2">
      {docType === "W-2" && <div>One W-2 required</div>}
      {docType === "taxreturn" && <div>One Tax Return required</div>}
      {docType === "paystubs" && <div>Two Paystubs are required</div>}
      {docType === "k1" && <div>One K-1 required</div>}
      {docType === "tax1099" && <div>One 1099 required</div>}
      {docType === "retirementBenefitLetter" && <div>One Retirement Benefit Letter required</div>}
      {docType === "vaDisabilityLetter" && <div>One VA Disability Letter</div>}
      {docType === "socialSecurityAwardLetter" && <div>One Social Security Award Letter required</div>}
    </div>

    {docType ? <MultiFileUpload
      docs={docs}
      onChange={onChange}
      numRequired={numRequired}
    /> : null}
  </div>
}

export default DocSelector