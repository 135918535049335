import { EquifaxPrequalConfig, TenantEquifaxConfig } from "@oneethos/shared"
import { TextInput } from "../forms"

type EquifaxPrequalFormProps = {
  prequal: EquifaxPrequalConfig
  setEquifaxConfig: (c: Partial<TenantEquifaxConfig>) => void
}

export const EquifaxPrequalForm = ({ prequal, setEquifaxConfig }: EquifaxPrequalFormProps) => {
  return <div>
    <div className="form-group">
      <label>Equifax Prequal Client ID</label>
      <TextInput
        value={prequal?.clientId || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, clientId: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax Prequal Client Secret</label>
      <TextInput
        value={prequal?.clientSecret || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, clientSecret: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax Prequal Member No</label>
      <TextInput
        value={prequal?.memberNo || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, memberNo: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax Prequal Security Code</label>
      <TextInput
        value={prequal?.securityCode || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, securityCode: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax Prequal Base URL</label>
      <TextInput
        value={prequal?.baseUrl || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, baseUrl: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax Prequal Model Number ("05200" for FICO 9 or "02958" for FICO 5)</label>
      <TextInput
        value={prequal?.modelNo || ''}
        onChange={val => setEquifaxConfig({ prequal: { ...prequal, modelNo: val } })}
      />
    </div>
  </div>
}