import React from 'react'

export const Section: React.FC = ({ children }): React.ReactElement => {
  return <div className="section wf-section">
    <div className="container">
      <div className="card-secondary">
        <div className="card-padding">
          {children}
        </div>
      </div>
    </div>
  </div>
}

export const MiniSection = ({ type="success", children }) => {
  return <div className="section wf-section mini-section">
    <div className="container">
      <div className="registration-prompt card-secondary">
        <div className={`alert alert-${type}`} style={{ marginBottom: 0 }}>
          {/* <div className="card-padding"> */}
            {children}
        </div>
      </div>
    </div>
  </div>
}

export default Section