import React, { useEffect, useState } from "react"
import { FaFileUpload, FaRegFile, FaTimesCircle } from "react-icons/fa"
import { FaFileCircleCheck } from "react-icons/fa6"
import { v4 } from 'uuid'
import byteSize from 'byte-size'
import { uploadFile } from "../services/fileUploadService"
import { toast } from "react-toastify"
import { UploadedDoc } from "@oneethos/shared"
import './image-uploader.scss'

type ImageUploaderProps = {
  onChange: (d: UploadedDoc) => void
  onRemove: () => void
  src?: string
  label: string
}

export const ImageUploader = ({ label, onChange, onRemove, src }: ImageUploaderProps) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [preview, setPreview] = useState('')
  const [uploading, setUploading] = useState(false)
  const [inputId,] = useState(v4())
  const id = `file_input_${inputId}`

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  return <div className="form-group">
    <div className="image-uploader">
      <label>{label}</label>
      <label htmlFor={id} className="upload-prompt">
        <div className="logo-preview" style={{ backgroundImage: `url(${preview || src})` }}>
          <div className="logo-prompt">
            <div className="lp-up">Click to Edit</div>
          </div>
          {preview || src ? <div
            className="clear-img" onClick={ev => {
              ev.preventDefault()
              onRemove()
            }}>
            <FaTimesCircle />
          </div> : null}
        </div>
      </label>

      <input
        type="file"
        id={id}
        accept="image/*"
        className="form-control-file"
        style={{ display: 'none' }}
        onChange={ev => {
          if (!ev.target.files || ev.target.files.length === 0) {
            setSelectedFile(undefined)
            return
          }

          setSelectedFile(ev.target.files[0])

          setUploading(true)
          uploadFile(ev.target.files?.[0] as File).then(res => {
            onChange(res)
          }).catch(ex => toast.error(ex.message)).finally(() => setUploading(false))
        }}
      />

    </div>
  </div>

}

export default ImageUploader