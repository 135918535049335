import React from "react"

export const TableCellSelect = ({ value, options, onSelect, disabled }) => {
  if (!disabled) {
    return <select className="form-select"
      value={value}
      onChange={ev => {
        onSelect(ev.target.value)
      }}
    >
      {options.filter(o => !o.disabled).map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
    </select>
  }

  return <div
    style={disabled ? { color: '#aaa' } : { cursor: 'pointer' }}
  >
    {options.find(o => o.value === value)?.label || value}
  </div>
}

export default TableCellSelect