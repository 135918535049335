import { Fragment, useEffect, useState } from "react"
import { Section } from "../components"
import api from "../api-client"
import { toast } from "react-toastify"
import './credit-report.scss'
import {
  CriteriaEvaluationResult, 
  CriteriaSpec, 
  EquifaxBankruptcy, 
  EquifaxCollection, 
  EquifaxPrequalReport, 
  EquifaxScoreModel, 
  EquifaxTrade, 
  dollars, 
  yyyymmdd, 
  errorToString
} from "@oneethos/shared"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { useSearchParams } from "react-router-dom"
import EquifaxDitReport from "../components/equifax-dit-report"


const CodedDescriptor = ({ code, description }) => {
  if (!code) return null

  return <div
    title={description}
    style={{
      whiteSpace: 'nowrap',
      maxWidth: '150px',
      fontSize: '0.8em',
      overflow: 'hidden',
      marginRight: '0.3em'
    }}><b>{code}</b> | <span style={{ color: '#aaa' }}>{description}</span></div>
}

const TradesByType = ({ trades }) => {
  const summary = {}

  trades.forEach(t => {
    let code = t.portfolioTypeCode?.code
    if (t.rawNarrativeCodes?.length) {
      for (const narrative of t.rawNarrativeCodes) {
        // codes that appear to considered mortgages
        if (['EF', 'EC'].includes(narrative)) {
          code = 'M'
          break
        }
      }
    }

    const val = summary[code] || 0
    if (
      // ignore revolving if authorized user
      (t.accountDesignator?.code === 'A' && code === 'R') ||
      // or if no balance
      !t.balance
    ) {
      return
    }

    summary[code] = val + (t.scheduledPaymentAmount || 0)
  })

  return <div>
    <div>Mortgage: {dollars(summary['M'] || 0)}</div>
    <div>Revolving: {dollars(summary['R'] || 0)}</div>
    <div>Installment: {dollars(summary['I'] || 0)}</div>
    <div>Credit Line: {dollars(summary['C'] || 0)}</div>
    <div>Other: {dollars(summary['O'] || 0)}</div>
    <div>Total: {Object.values(summary).reduce((prev: number, cur: number) => prev + cur, 0)}</div>
  </div>
}

const Trades = ({ trades }) => {
  const [expand, setExpand] = useState(false)

  return <div className="trades">
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Trades ({trades?.length || 0})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>
    {expand && trades?.length ? <>
      <TradesByType trades={trades} />
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Acct #</th>
            <th>Limit/High</th>
            <th>Bal</th>
            <th>Pmt</th>
            <th>Type</th>
            <th>Narrative</th>
            <th>Dates</th>
            <th>History</th>
            <th>Terms/Act/Purchased</th>
            <th>30</th>
            <th>60</th>
            <th>90</th>
          </tr>
        </thead>
        <tbody>
          {/* {trades?.filter(t => (
          [2,3,4].includes(t.rate?.code)
          // t.balance
        )).map((trade: EquifaxTrade, idx) => { */}
          {trades?.map((trade: EquifaxTrade, idx) => {
            // delete trade["24MonthPaymentHistory"]
            // delete trade["paymentHistory1to24"]
            return <Trade key={idx} trade={trade} />
          })}
        </tbody>
      </table>
    </> : null}
  </div>
}

type TradeProps = {
  trade: EquifaxTrade
}

const mmyyyy = (date?: Date): string => {
  if (!date) return ''
  if (typeof date === 'string') return date

  return `${date.getMonth() + 1}/${date.getFullYear()}`
}

const TradeHistoryGrid = ({ trade }) => {
  if (!trade.dateReported) return <tr><td>NO LAST PAYMENT DATE</td></tr>

  const maxYear = trade.dateReported.getFullYear()
  const startMonth = trade.dateReported.getMonth()
  const history = [...trade.paymentHistory1to24]
  history.splice(12, 1)
  return <div>
    <div className="year">
      <div className="months m-hdr">
        <div className="year-label"></div>
        <div>J</div> <div>F</div> <div>M</div> <div>A</div>
        <div>M</div> <div>J</div> <div>J</div> <div>A</div>
        <div>S</div> <div>O</div> <div>N</div> <div>D</div>
      </div>
    </div>
    {[maxYear, maxYear - 1, maxYear - 2].map(year => <div className="year" key={year}>
      <div className="months">
        {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(month => {
          if (year === maxYear) {
            return <div>{month > startMonth ? '' : history[startMonth - month].code}</div>
          } else if (year === maxYear - 1) {
            return <div>{history[12 + startMonth - month].code}</div>
          } else {
            const idx = 24 + startMonth - month
            return <div>{idx < history.length ? history[idx].code : ''}</div>
          }
        })}
        <div className="year-label">{year}</div>
      </div>
    </div>)}
  </div>
}


const Trade = ({ trade }: TradeProps) => {
  const [showJson, setShowJson] = useState(false)

  let className = ''
  if (
    trade.rate?.code > 1 ||
    trade.thirtyDayCounter ||
    trade.ninetyDayCounter ||
    trade.sixtyDayCounter
  ) {
    className = 'table-danger'
  }

  return <>
    <tr className={className} onClick={() => setShowJson(!showJson)} style={{ marginBottom: '1em' }}>
      <td className="amt">{trade.rate?.code}</td>
      <td className="amt">{trade.accountNumber}</td>
      <td className="amt">{trade.creditLimit ? dollars(trade.creditLimit, 0) : (trade.highCredit ? dollars(trade.highCredit, 0) : '')}</td>
      <td className="amt">{dollars(trade.balance || 0, 0)}</td>
      <td className="amt">{dollars(trade.scheduledPaymentAmount || 0, 0)}</td>
      <td className="amt">{trade.portfolioTypeCode?.code}{trade.rawNarrativeCodes?.find(n => ['EF', 'EC'].includes(n)) ? '/M' : ''}</td>
      <td style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {trade.narrativeCodes?.map(({ description }, i) => (
          <div key={i} title={description}>{description}</div>
        ))}
      </td>
      <td className="trade-dates">
        <div>
          <span>Date Reported:</span>
          <span> {mmyyyy(trade.dateReported)}</span></div>
        <div>
          <span>Date Opened:</span>
          <span> {mmyyyy(trade.dateOpened)}</span></div>
        <div>
          <span>Last Payment Date:</span>
          <span> {mmyyyy(trade.lastPaymentDate)}</span></div>
        <div>
          <span>Date Closed:</span>
          <span> {mmyyyy(trade.closedDate)}</span></div>
        <div>
          <span>Months Reviewed:</span>
          <span> {trade.monthsReviewed}</span></div>
      </td>
      <td className="trade-history-grid">
        {trade.paymentHistory1to24 ? <TradeHistoryGrid trade={trade} /> : null}
      </td>
      <td>
        <CodedDescriptor {...trade.accountTypeCode} />
        <CodedDescriptor {...trade.accountDesignator} />
        <CodedDescriptor {...trade.termsFrequencyCode} />
        <CodedDescriptor {...trade.activityDesignatorCode} />
        <CodedDescriptor {...trade.purchasedFromOrSoldCreditorIndicator} />
      </td>
      <td>{trade.thirtyDayCounter}</td>
      <td>{trade.sixtyDayCounter}</td>
      <td>{trade.ninetyDayCounter}</td>
    </tr>
    {showJson ? <tr onClick={() => setShowJson(!showJson)}>
      <td colSpan={8}>
        <pre>
          {JSON.stringify(trade, false, ' ')}
        </pre>
      </td>
    </tr> : null}
  </>
}

type ScoreProps = { model: EquifaxScoreModel }

const Score = ({ model }: ScoreProps) => {
  const [expand, setExpand] = useState(false)

  return <div className="cr-scoring" >
    {model.rejects?.map(({ code }) =>
      <div key={code} className="cr-reject score">Score: Reject ({code})</div>
    )}
    {model.score ? <>
      <div className="score" onClick={() => setExpand(!expand)}>
        Score: {model.score}
        {expand ? <FaChevronDown /> : <FaChevronRight />}
      </div>
      {expand ? <div>
        {model.reasons?.map(r => <div key={r.code}>
          <b>{r.code}</b> | <span>{r.description}</span>
        </div>)}
      </div> : null}
    </> : null
    }
  </div >
}

type BankruptcyProps = {
  bankruptcies: EquifaxBankruptcy[]
}

const Bankruptcies = ({ bankruptcies }: BankruptcyProps) => {
  const [expand, setExpand] = useState(false)

  return <div>
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Bankruptcies ({bankruptcies.length})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Filed</th>
          <th>Industry</th>
          <th>Filer</th>
          <th>Type</th>
          <th>Intent/Disposition</th>
          <th>Prior Int/Dis</th>
        </tr>
      </thead>
      <tbody>
        {bankruptcies?.map((b, i) => <tr key={i} title={JSON.stringify(b, false, ' ')}>
          <td>{yyyymmdd(b.dateFiled)}</td>
          <td>{b.industryCode}</td>
          <td>{b.filer}</td>
          <td>{b.type}</td>
          <td><CodedDescriptor {...b.currentIntentOrDispositionCode} /></td>
          <td><CodedDescriptor {...b.priorIntentOrDispositionCode} /></td>
        </tr>)}
      </tbody>
    </table> : null}

  </div >
}

type CollectionsProps = {
  collections: EquifaxCollection[]
}

const Collections = ({ collections }: CollectionsProps) => {
  const [expand, setExpand] = useState(false)

  return <div>
    <div className="section-hdr" onClick={() => setExpand(!expand)}
      style={{}}>
      Collections ({collections.length})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Delinquency</th>
          <th>Balance</th>
          <th>Orig Amt</th>
          <th>Customer no</th>
          <th>Classification</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {collections.map((c, i) => <tr key={i} title={JSON.stringify(c, false, ' ')}>
          <td>{yyyymmdd(c.dateOfFirstDelinquency)}</td>
          <td>{c.balance}</td>
          <td>{c.originalAmount}</td>
          <td>{c.customerNumber}</td>
          <td><CodedDescriptor {...c.creditorClassificationCode} /></td>
          <td><CodedDescriptor {...c.statusCode} /></td>
        </tr>)}
      </tbody>
    </table> : null}
  </div>
}

type EvaluationProps = {
  evaluation: CriteriaEvaluationResult
}

const CriteriaCell = ({ spec }: { spec: CriteriaSpec }) => {
  return <td>{
    Object.entries(spec).map(([key, value]) => <div key={key}>
      {key}:&nbsp;
      <b>
        {typeof value.getDay === 'function' ? yyyymmdd(value as Date) : value.toString()}
      </b>
    </div>)
  }
  </td>

}

const Evaluation = ({ evaluation }: EvaluationProps) => {
  const [expand, setExpand] = useState(false)

  const passed = evaluation.byCriteria.filter(r => r.result === 'pass')

  return <div className="eval">
    <div style={{ cursor: 'pointer' }}>
      {passed.length
        ? <div className="badge text-bg-success" onClick={() => setExpand(!expand)}>
          Approved&nbsp;({passed.length})
          {expand ? <FaChevronDown /> : <FaChevronRight />}
        </div>
        : <div className="badge text-bg-danger" onClick={() => setExpand(!expand)}>
          Declined&nbsp;
          {expand ? <FaChevronDown /> : <FaChevronRight />}
        </div>}
    </div>
    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Result</th>
          <th>Min</th>
          <th>DTI</th>
          <th>Bankruptcy</th>
          <th>Collections</th>
          <th>Past Due</th>
        </tr>
      </thead>
      <tbody>
        {evaluation.byCriteria.map((res, i) => <Fragment key={i}>
          <tr>
            <td>{res.result === 'pass' ? <FaCircleCheck color='green' /> : <FaCircleXmark color='red' />}</td>
            <td>{res.criteria.minScore}</td>
            <td>{res.criteria.debtToIncome}</td>
            <CriteriaCell spec={res.criteria.bankruptcy} />
            <CriteriaCell spec={res.criteria.collections} />
            <CriteriaCell spec={res.criteria.pastDue} />
          </tr>
          {res.result === 'fail' ? <tr>
            <td colSpan={7}>
              {res.reasons.map((reason, j) => <div key={j}>{reason}</div>)}
            </td>
          </tr> : null}
        </Fragment>)}
      </tbody>
    </table> : null}
  </div>
}

type CreditReportItemProps = {
  report: EquifaxPrequalReport
  item: any
}

const CreditReportItem = ({ report, item }: CreditReportItemProps) => {
  const [showJson, setShowJson] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const {
    bankruptcies,
    collections,
    inquiries,
    trades,
    models,
    subjectSocialNum,
    subjectName,
    addresses,
    ...rest
  } = report

  return <li className="list-group-item credit-report" style={{ overflowX: 'scroll' }}>
    <h5 onClick={() => setShowJson(!showJson)}>
      Prequal: {item.customerName}
    </h5>
    {item.comboName ? <div>Joint Application With: {item.comboName}</div> : null}
    <div>Pulled: {item.createdDate.toLocaleString()}</div>
    {report.isNoHit() ? <>
      <div className="badge text-bg-secondary">No-Hit</div>
    </> : <>
      <div className="cr-summary">
        <div className="info">
          {showJson ? <pre style={{ fontSize: '0.8em' }}>{JSON.stringify({ ...rest, models }, false, ' ')}</pre> : null}
          {report.models?.map((model, i) => <Score key={i} model={model} />)}
          <div>Stated Income: {dollars(item.evaluation.statedGrossMonthlyIncome)}</div>
          <div>Monthly Debt: {dollars(report.monthlyDebt || 0, 0)}</div>
          <div>Current DTI: {item.evaluation.currentDti?.toFixed(1)}%</div>
          <div>Post DTI: {item.evaluation.postDti?.toFixed(1)}%</div>
          <div>Estimated Payment: {dollars(item.evaluation.estimatedPayment)}</div>
          <div>Has Late Payments: {report.hasLatePayment12 || report.hasLatePayment24 ? 'yes' : 'no'}</div>
        </div>
      </div>
      <Evaluation evaluation={item.evaluation} />
      <div className="section-hdr">Inquiries ({inquiries?.length || 0})</div>
      <Bankruptcies bankruptcies={bankruptcies} />
      <Collections collections={collections} />
      <Trades trades={trades} />
    </>}
  </li>
}

export const CreditReportsPage = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [searchParams] = useSearchParams()

  const doSearch = (params: URLSearchParams) => {
    setLoading(true)
    api.get(`/credit-reports?${params.toString()}`).then(res => {
      const _list = res.list.map(item => {
        let report = item
        if (item.reportType === 'prequal' || item.reportType === 'prequal-combined') {
          const rawReport = item.report.consumers?.equifaxUSConsumerCreditReport[0]
          report = new EquifaxPrequalReport(rawReport)
          report.createdDate = new Date(item.createdDate)
        }

        return {
          report,
          item
        }
      })

      setList(_list)
    }).catch(ex => toast.error(errorToString(ex))).finally(() => setLoading(false))
  }

  useEffect(() => {
    const obj = Object.fromEntries(searchParams)
    if (Object.keys(obj).length) {
      doSearch(searchParams)
    }
  }, [searchParams])

  return <Section>
    <h3>Credit Reports Page</h3>
    <div className="alert alert-info">Prequal reports are deleted after 30 days</div>
    <div className="form-group">
      <label>Search by Name</label>
      <input
        type="text"
        className="form-control"
        value={search}
        onChange={ev => setSearch(ev.target.value)} />
      <div className="text-small">must match first or last name exactly and is case-sensitive</div>
    </div>
    <div className="buttons mb-3">
      <button type="button" className="btn btn-primary" onClick={() => doSearch(new URLSearchParams({ search }))}>
        Search
      </button>
    </div>
    <div className="list-group">
      {loading ? <Spinner size={SpinnerSize.large} /> : <>
        {list.length === 0 && <div className="alert alert-secondary">No reports match your search</div>}
        {list.map((entry, i) => {
          const isPrequal = ['prequal', 'prequal-combined'].includes(entry.item.reportType)
          return isPrequal ? <CreditReportItem key={i}
            report={entry.report}
            item={entry.item}
          /> : <EquifaxDitReport report={entry.item.report} />
        })}
      </>
      }
    </div>
  </Section>
}

export default CreditReportsPage