import {
  ConsumerApplicationIntegration,
  IntegrationLabels,
} from '@oneethos/shared'
import { PasswordInput } from '../forms'


export const BackendConfig = ({ config, setConfig }) => {
  return <div>
    <h4>Backend</h4>
    <div className="form-group">
      <div className="form-group">
        <select
          className="form-select"
          value={config.consumerApplicationIntegration}
          onChange={ev => setConfig({
            ...config,
            consumerApplicationIntegration: ev.target.value
          })}
        >
          <option>n/a</option>
          <option value="Abrigo">{IntegrationLabels[ConsumerApplicationIntegration.Abrigo]}</option>
          <option value="DecisionPro">{IntegrationLabels[ConsumerApplicationIntegration.DecisionPro]}</option>
        </select>
      </div>
      {config.consumerApplicationIntegration === 'Abrigo' ?
        <>
          <div className="form-group">
            <label>Abrigo Environment</label>
            <select
              className="form-select"
              value={config.abrigoEnv}
              onChange={ev => setConfig({ ...config, abrigoEnv: ev.target.value })}
            >
              <option>n/a</option>
              <option value="sandbox">Sandbox</option>
              <option value="uat">UAT</option>
              <option value="production">Production</option>
            </select>
          </div>
          <div className="form-group">
            <label>Abrigo Client ID</label>
            <input
              type="text"
              value={config.abrigoClientId || ''}
              className="form-control"
              onChange={ev => setConfig({ ...config, abrigoClientId: ev.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Abrigo Client Secret</label>
            <PasswordInput
              value={config.abrigoClientSecret || ''}
              onChange={val => setConfig({ ...config, abrigoClientSecret: val })}
            />
          </div>
          <div className="form-group">
            <label>Abrigo Product ID</label>
            <input
              type="text"
              value={config.abrigoProductId || ''}
              className="form-control"
              onChange={ev => setConfig({ ...config, abrigoProductId: ev.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Abrigo Workflow Template ID</label>
            <input
              type="text"
              value={config.abrigoSolarWorkflowTemplateId || ''}
              className="form-control"
              onChange={ev => setConfig({
                ...config,
                abrigoSolarWorkflowTemplateId: ev.target.value
              })}
            />
          </div>
          <div className="form-group">
            <label>Mark Step as Complete on Doc Upload</label>
            <input type="checkbox"
              onChange={ev => setConfig({
                ...config,
                abrigoMarkStepCompleteOnDocUpload: ev.target.checked
              })}
              checked={config.abrigoMarkStepCompleteOnDocUpload}
            />
          </div>
        </> : null}
      {config.consumerApplicationIntegration === 'DecisionPro' ? <>
        {/* <div className="form-group">
            <label>Airtable Access Token</label>
            <PasswordInput
              value={config.airtableAccessToken}
              onChange={val => setConfig({ ...config, airtableAccessToken: val })}
            />
          </div> */}
        <div className="form-group">
          <label>DecisionPro Submit URL</label>
          <input
            type="text"
            value={config.decisionProSubmitUrl || ''}
            className="form-control"
            onChange={ev => setConfig({ ...config, decisionProSubmitUrl: ev.target.value })}
          />
        </div>
        <div className="form-group">
          <label>DecisionPro Product Name</label>
          <p className="small">e.g., "Consumer Solar Loan 2/22/24"</p>
          <input
            type="text"
            value={config.decisionProProductName || ''}
            className="form-control"
            onChange={ev => setConfig({ ...config, decisionProProductName: ev.target.value })}
          />
        </div>
      </> : null}
    </div>
  </div>
}