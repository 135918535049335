import { useState, ChangeEvent, KeyboardEvent } from "react"
import api from "../api-client"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { Spinner } from "@fluentui/react"
import { IoIosSearch } from "react-icons/io"

const InstallerSearch: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const handleSearch = () => {
    setLoading(true)
    api.get(`/search?q=${encodeURIComponent(searchInput)}`)
      .then(res => {
        const installers = res
        if (installers) {
          navigate(`/search-results`, { state: { installers } })
        } 
        setSearchInput("")
      })
      .catch(ex => {
        toast.warn(ex?.error || "An error occurred while searching")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <div className="d-flex align-items-center">
      <div className="search-container" style={{ width: '320px' }} >
        <IoIosSearch className="search-icon" style={{ top: '25%' }} size={20} />
        <input
          value={searchInput}
          type="search"
          aria-label="search"
          className="form-control search-input"
          placeholder="Search installer by email"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button
        className="btn btn-secondary ms-2 btn-sm"
        type="button"
        onClick={handleSearch}
        disabled={loading}
      >
        {loading ? <Spinner /> : "Search"}
      </button>
    </div>
  )
}

export default InstallerSearch
