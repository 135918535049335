
export const CitizenshipOptions = {
  1: 'U.S. Citizen',
  2: 'Non-resident Alien',
  3: 'Resident Alien',
}

export type CitizenshipValue = keyof typeof CitizenshipOptions

export class Citizenship {
  static options() {
    return Object.entries(CitizenshipOptions)
  }

  static getLabel(val: CitizenshipValue) {
    return CitizenshipOptions[val]
  }
}