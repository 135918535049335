import React, { useEffect, useState } from 'react'
import { Installer } from '@oneethos/shared'
import { AuthGetResponse, NumbersACH, AccountBase, PlaidError, Institution } from 'plaid'
import api from '../api-client'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { usePlaid } from '../hooks'

const plaidErrorToString = (err: PlaidError | string) => {
  if (typeof err === 'string') {
    return err
  } else if (err.error_message) {
    return err.display_message || err.error_message
  } else {
    return JSON.stringify(err)
  }
}

type PlaidAuthProps = {
  prompt: string
  onAuthSelect: (params: {
    ach: NumbersACH,
    account: AccountBase,
    institution: Institution
  }) => void
  onManual: () => void
}

type OEAuthGetResponse = AuthGetResponse & { institution: Institution }

export const PlaidAuthSelect = ({ onAuthSelect, onManual, prompt }: PlaidAuthProps) => {
  const [agr, setAuthGetResponse] = useState<OEAuthGetResponse>(null)
  const [plaidError, setPlaidError] = useState<PlaidError | string>()

  const { ready, open } = usePlaid({
    onSuccess: (public_token, metadata) => {
      const query = new URLSearchParams({ public_token })
      api.get(`/plaid/auth?${query.toString()}`)
        .then(setAuthGetResponse)
        .catch(setPlaidError)
    },
    onError(err) {
      console.error(`plaid error`, err)
      setPlaidError(err)
    },
    onExit(reason) {
      console.error('plaid exit', reason)
      if (reason) {
        setPlaidError(reason)
      } else {
        onManual()
      }
    }
  })

  return <div>
    {agr ? <>
      <p>{prompt}</p>
      <div className="list-group">
        {agr.accounts.map(a => <div
          key={a.account_id}
          className="list-group-item"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const ach = agr.numbers.ach.find(ach => ach.account_id === a.account_id)
            onAuthSelect({ ach, account: a, institution: agr.institution })
          }}
        >
          <div>{a.name} ({a.mask})</div>
          <div style={{ color: '#aaa', fontSize: '0.8em' }}>{a.subtype}</div>
        </div>)}
      </div>
    </> : <>
      {plaidError ? <div>
        <div className="alert alert-danger">
          <p>There was an error linking your autopay account. You may retry or manually enter your autopay details.</p>
          <p><b>Error:</b>&nbsp;{plaidErrorToString(plaidError)}</p>
        </div>
        <div className="buttons" style={{ textAlign: 'left' }}>
          <button type="button" className="btn btn-primary" onClick={() => { setPlaidError(undefined); open() }}>Retry</button>
          <button type="button" className="btn btn-secondary" onClick={onManual}>Enter Manually</button>
        </div>
      </div> : <div className="buttons" style={{ textAlign: 'left' }}>
        <button type="button" disabled={!ready} className="btn btn-primary" onClick={() => open()}>Setup Auto-payment</button>
        <button type="button" className="btn btn-light" onClick={onManual}>Enter Manually</button>
      </div>}
    </>}
  </div>
}

export default PlaidAuthSelect