import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from '@fluentui/react'
import { useLocalStorage, useUtmQuery } from '../hooks'
import api from '../api-client'
import { useNavigate } from 'react-router'
import actions from '../actions'
import { AppContext } from '../appContext'
import { AuthError, LoginButton, Section } from '../components'
import { Navigate } from 'react-router-dom'
import { errorToString } from '@oneethos/shared'

export const LoginPage = () => {
  const { route, code, msg } = useUtmQuery()
  const [email, setEmail] = useState('')
  const [installerID, setInstallerId] = useState('')
  const [success, setSuccess] = useState('')
  const [info, setInfo] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [requireInstallerID, setRequireInstallerID] = useState(false)
  const { dispatch, state: { registration: { installer, authError } } } = useContext(AppContext)
  const navigate = useNavigate()

  if (installer) {
    // redirect if they're already logged in
    return <Navigate to="/installer-pos" />
  }

  return <div className="login-page">
    {code ? <AuthError authError={code} /> : null}
    <Section>
      <p>
        If you have registered previously, you can request a new
        login link be sent to your email.
      </p>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          disabled={submitting}
          onChange={ev => setEmail(ev.target.value)}
        />
      </div>
      {requireInstallerID ? <div className="form-group">
        <label>Installer ID</label>
        <input
          type="text"
          className="form-control"
          value={installerID}
          disabled={submitting}
          onChange={ev => setInstallerId(ev.target.value)}
        />
      </div> : null}
      {info ? <div className="alert alert-info">{info}</div> : null}
      <div className="buttons" style={{ marginBottom: '1em' }}>
        <LoginButton
          email={email}
          submitting={submitting}
          setSubmitting={tf => {
            setSubmitting(tf)
            if (tf) {
              dispatch(actions.setAuthError(''))
            }
          }}
          onSuccess={msg => {
            // toast.success(msg)
            navigate('/verify-email', { state: { code: msg } })
          }}
          installerID={installerID}
          route={route}
          onError={ex => {
            // console.log(typeof ex, ex.message)
            if (ex.code === 'INSTALLER_ID_REQUIRED') {
              setRequireInstallerID(true)
              setInfo('Installer ID Required')
            } else {
              dispatch(actions.setAuthError(errorToString(ex)))
            }
          }}
        />
      </div>
      {success ? <div className="alert alert-success">{success}</div> : null}
    </Section>
    <Section>
      <p>If you have not registered, please complete your registration</p>
      <button type="button"
        className="btn btn-success"
        onClick={() => navigate(`/profile`)}
      >Register Now</button>
    </Section>
  </div>
}

export default LoginPage
