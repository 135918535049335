import { useSearchParams } from "react-router-dom"
import { AppContext, ApplicationState } from "../appContext"
import { useContext, useEffect, useState } from "react"
import { useLocalStorage } from "./use-local-storage"
import { Dictionary, Installer } from "@oneethos/shared"
import api from '../api-client'

export * from './use-local-storage'
export * from './use-plaid'
export * from './use-api'
export * from './use-is-mobile'
export * from './use-screen-size'

export const useUtmQuery = (): Record<string, string> => {
  const [urlParams] = useSearchParams()
  const [campaign] = useLocalStorage('utm_campaign', undefined)
  const [branchId] = useLocalStorage('utm_medium', undefined)
  const [installerID] = useLocalStorage('utm_source', undefined)
  const obj = Object.fromEntries(urlParams)

  return {
    ...obj,
    
    utm_medium: obj.utm_medium || branchId,
    utm_source: obj.utm_source || installerID,
    utm_campaign: obj.utm_campaign || campaign,

    branchId: obj.utm_medium || branchId,
    installerID: obj.utm_source || installerID,
    campaign: obj.utm_campaign || campaign
  }
}

// convenience hook to avoid deep state access
export const useAppState = (): ApplicationState => {
  return useContext(AppContext).state
}

type InstallerMapHookResult = {
  installersMap: Dictionary<Installer>
  imapError: Error | string | undefined
}

export const useInstallerMap = (installerID?: string): InstallerMapHookResult => {
  const [installersMap, setInstallersMap] = useState<Dictionary<Installer>>({})
  const [imapError, setImapError] = useState()
  const { registration: { installer } } = useAppState()
    
  useEffect(() => {
    if (installer?.isAdmin) {
      const search = new URLSearchParams({
        installerID: installerID || installer.installerID
      })

      api.get(`/installers?${search.toString()}`).then(_installers => {
        const map = {}
        _installers.forEach(i => map[i._id] = i)
        setInstallersMap(map)
      }).catch(ex => setImapError(ex.error || JSON.stringify(ex)))
    }
  }, [installer?.isAdmin, installerID, installer.installerID])

  return { installersMap, imapError }
}