import { Installer } from "@oneethos/shared"
import { InstallerAction } from "../reducers/registrationReducer"

export const actions = {
  setInstallerLoading(): InstallerAction {
    return { type: 'SET_INSTALLER_LOADING' }
  },

  setInstaller(installer: Installer): InstallerAction {
    return { type: 'SET_INSTALLER', data: installer }
  },

  setInstallerToken(token: string): InstallerAction {
    return { type: 'SET_INSTALLER_TOKEN', data: token }
  },

  setAuthError(err: string): InstallerAction {
    return { type: 'SET_AUTH_ERROR', data: err }
  },

  logout(): InstallerAction {
    return { type: 'LOGOUT' }
  },

  setRegistrationDeadline(d: Date): InstallerAction {
    return { type: 'SET_REGISTRATION_DEADLINE', data: d }
  }
}

export default actions