import { useCallback, useState } from "react"
import api from '../../api-client'
import { FaRegFile } from "react-icons/fa"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { toast } from "react-toastify"
import fileDownload from "../../lib/file-download"
import { useAppState } from "../../hooks"
import { LoanDocument, RequiredDocConfig, StatusData, UploadDocType } from "@oneethos/shared"

type DocLinkProps = {
  doc: LoanDocument
  slotConfig: RequiredDocConfig
  project: StatusData
  uploadType?: UploadDocType
}

export const DocLink = ({ doc, slotConfig, project, uploadType }: DocLinkProps) => {
  const { registration: { installer }} = useAppState()
  const [loading, setLoading] = useState(false)
  const suffix = doc?.id || `${encodeURIComponent(uploadType)}/${encodeURIComponent(slotConfig.slot)}`
  const path = `/loanapps/${project.id}/docs/${suffix}`

  const download = useCallback((ev) => {
    ev.preventDefault()
    setLoading(true)
    api.authenticatedFetch(path, {
      responseType: 'blob'
    }).then(async res => {
      const blob = await res.blob()
      // open inline if possible rather than downloading
      // fileDownload(blob, doc.blobName, doc.contentType)
      window.open(URL.createObjectURL(blob), '_blank')
    }).catch(ex => toast.error(ex.error, { autoClose: false }))
      .finally(() => setLoading(false))
  }, [])

  return ((installer.access === 'support' || installer.access === 'super_admin') && doc?.blobName && !loading) ?
    <div className="meta">
      <a href={`/api/${path}`} onClick={download}>
        <FaRegFile />&nbsp;<span>{doc.docType}</span>
      </a>
      <div className="filename">{doc.label}</div>
    </div> : <div style={{ color: '#aaa' }} title="file unavailable">
      {loading ? <Spinner size={SpinnerSize.small} /> : <FaRegFile />}
      &nbsp;<span>{doc?.label || doc?.docType || slotConfig.slot}</span>
    </div>
}
