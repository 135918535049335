import React, { useRef, useEffect, useState, useCallback } from 'react'
import api from '../api-client'
import { Section } from './section'
import { Dictionary, LoggedUpload, StatusData, daysAgo } from '@oneethos/shared'
import { useAppState, useInstallerMap } from '../hooks'
import { ProjectListItem } from './project-list-item'
import { useParams } from 'react-router-dom'
import { Spinner } from '@fluentui/react'
import ProjectSearch from './project-search'

type UIStatusData = StatusData & { hasUpload?: LoggedUpload }

export const ProjectList = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [filteredList, setFilteredList] = useState<UIStatusData[]>([])
  const [statusFilter, setStatusFilter] = useState('Needs Action')
  const [
    installersStatusCounts,
    setInstallersStatusCounts
  ] = useState<[string, number][]>([])
  const [assigneeFilter, setAssigneeFilter] = useState('')
  const { installerID } = useParams()
  const {
    registration: { installer },
    installersStatusConfig: isc
  } = useAppState()
  const list = useRef<UIStatusData[]>([])
  const { installersMap, imapError } = useInstallerMap(installerID)

  const filterByStatus = useCallback((status: string) => {
    if (status === 'Active Projects') {
      const daysAgo60 = daysAgo(60)
      return list.current.filter(p => {
        if (p.lastStatusChange > daysAgo60) {
          return true
        } else {
          return (
            !p.isClosed &&
            (p.lastStatusChange && p.lastStatusChange <= daysAgo60)
          )
        }
      })
    } else if (status === 'Needs Action') {
      return list.current.filter(p => (
        (!installer.isInstaller && p.docsNeedSupportActionCount > 0) ||
        (installer.isInstaller && p.docsNeedInstallerActionCount > 0)
      ))
    } else if (status === 'All Projects') {
      return [...list.current]
    } else {
      return list.current.filter(p => p.installersStatus === status)
    }
  }, [installer.access, installer.isInstaller])

  const fetchLoanApps = useCallback(() => {
    const search = new URLSearchParams({
      installerID: installerID || installer.installerID
    })

    Promise.all([
      api.getProjects(search)
    ]).then(([_list]) => {
      const statusCounts: Record<string, number> = {}
      _list.forEach(item => {
        statusCounts[item.installersStatus] = (statusCounts[item.installersStatus] || 0) + 1
      })
      list.current = _list
      setFilteredList(filterByStatus(statusFilter))
      const sortedCounts = Object.entries(statusCounts)
      sortedCounts.sort((a, b) => a[0].localeCompare(b[0]))
      setInstallersStatusCounts(sortedCounts)
    }).catch(ex => {
      console.error(ex)
      setError(ex)
    }).finally(() => setLoading(false))
  }, [installerID, installer.installerID])

  useEffect(() => {
    // we need to wait for isc to load before we can update the progress, but 
    // we only really need this to run once so we exclude the other items from
    // dependency array
    if (!loading && !isc.loading) {
      list.current = list.current.map(p => {
        const status = isc.dict[p.installersStatus]
        p.progress = status?.progress
        return p
      })

      setFilteredList(filterByStatus(statusFilter))
    }
  }, [loading, isc.loading])

  useEffect(() => {
    fetchLoanApps()
  }, [installerID, installer.installerID, fetchLoanApps])

  useEffect(() => {
    let l = filterByStatus(statusFilter)
    if (assigneeFilter) {
      l = l.filter(p => p.authorizedInstallers.includes(assigneeFilter))
    }
    setFilteredList(l)
  }, [statusFilter, assigneeFilter, filterByStatus])

  if (loading || isc.loading) {
    return <Section><Spinner /></Section>
  }

  return <Section>
    <div className="project-list">
      <div className="section-title">
        <h2 className="margin-bottom-0">
          {['super_admin', 'support'].includes(installer.access) ? <>
            <span>{installerID || installer.installerID}</span> |&nbsp;
          </> : null}
          Projects
        </h2>
      </div>
      <ProjectSearch
        list={list.current}
        onFilter={setFilteredList}
        resetStatusFilter={() => setStatusFilter('All Projects')} />
      <div style={{ marginBottom: '1rem', maxWidth: '350px' }} className="form-group">
        <label>Filter by Status</label>
        <select
          className="form-select form-control"
          value={statusFilter}
          onChange={ev => setStatusFilter(ev.target.value)}
        >
          <optgroup label="General Status Filter">
            <option>Needs Action</option>
            <option>Active Projects</option>
            <option>All Projects</option>
          </optgroup>
          <optgroup label="Detailed Status Filter">
            {installersStatusCounts.map(([step, count]) => {
              const word = `project${count && count > 1 ? `s` : ''}`
              return <option key={step} value={step}>{step} {count ? `(${count} ${word})` : ''}</option>
            })}
          </optgroup>
        </select>
      </div>
      {installer.isAdmin ? <div style={{ marginBottom: '1rem', maxWidth: '350px' }} className="form-group">
        <label>Filter by Solar Representative</label>
        <select
          className="form-select form-control"
          value={assigneeFilter}
          onChange={ev => setAssigneeFilter(ev.target.value)}
        >
          <option value=''>All Representatives</option>
          {Object.keys(installersMap).map(id => <option key={id} value={id}>
            {installersMap[id].email}
          </option>)}
        </select>
      </div> : null}

      {error ? <div className="alert alert-danger">
        There was an error loading your project list, please contact your administrator
        <div>{JSON.stringify(error)}</div>
      </div> : null}
      <div className="list-group">
        {filteredList.map((project, idx) => (
          <ProjectListItem
            project={project}
            installersMap={installersMap}
            onChange={_project => {
              const list = [...filteredList]
              list.splice(idx, 1, _project)
              setFilteredList(list)
            }}
            key={idx}
          />
        ))}
      </div>
      {(!error && filteredList.length === 0) ? <div className="alert alert-info">
        There are no projects assigned to you in this filter. If this is unexpected,
        please contact your account administrator.
      </div> : null}
    </div>
  </Section>
}
