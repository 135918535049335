import registrationActions from './registrationActions'
import installersStatusActions from './installersStatusActions'
import tenantActions from './tenantActions'

const actions = {
  ...registrationActions,
  ...installersStatusActions,
  ...tenantActions
}

export default actions