import { SolarDoc } from "@oneethos/shared"
import { useState } from "react"
import { FaFile, FaFileUpload, FaTimesCircle } from "react-icons/fa"

type MultiFileUploadProps = {
  docs: (File & SolarDoc)[]
  id?: string
  onChange: (docs: File[]) => void
  numRequired?: number
}

export const MultiFileUpload = ({
  docs = [],
  id,
  onChange,
  numRequired
}: MultiFileUploadProps) => {
  const [_id,] = useState(id || crypto.randomUUID())

  const allowAddFile = !numRequired || docs?.length < numRequired

  return <div className="upload-prompt multi-up">
    <div className="file-cont">
      {docs?.length ? Object.entries(docs).map(([i, doc]) => <div key={i} className="attached-file">
        <FaFile color="white" size={50} />
        <div className="file-name">{doc.name || doc.label}</div>
        <div
          className="detach"
          onClick={ev => {
            ev.preventDefault()

            const _docs = [...docs]
            _docs.splice(parseInt(i), 1)
            onChange(_docs)
          }}>
          <FaTimesCircle color="#666" size={20} />
        </div>
      </div>) : null}
    </div>
    {allowAddFile ? <>
      <label htmlFor={_id}>
        {!docs?.length ? <div className="add-docs">Add documents</div> : null}
        <div>
          <div className="btn btn-dark">
            <FaFileUpload color="white" />
            <span>Add File(s)...</span>
          </div>
        </div>
      </label>
      <input
        id={_id}
        type="file"
        className="form-control"
        multiple
        onChange={ev => {
          // seems there may be a bug where uploading the same file after it's been deleted doesn't take 
          onChange([...docs, ...Array.from(ev.target.files)])
        }}
        style={{ display: 'none' }}
      />
    </> : null}
  </div>
}

export default MultiFileUpload