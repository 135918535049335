import React from 'react'
import { FaTrash } from 'react-icons/fa'
import './array-editor.scss'

type ArrayEditorProps = {
  prompt: string
  values: string[]
  onChange: (a: string[]) => void
}

export const ArrayEditor = ({ prompt, values, onChange }: ArrayEditorProps) => {
  const [text, setText] = React.useState<string>('')

  return <div className="array-editor">
    <div className="list-group">
      {values.length === 0 && <div className="list-group-item p-2 bg-light text-secondary">No aliases configured</div>}
      {values.map((val, idx) => {
        return <div key={idx}
          className={['list-group-item p-2'].join(' ')}
        >
          <span>{val}</span>
          <div className="action-buttons me-2">
            <FaTrash onClick={() => {
              const vals = [...values]
              vals.splice(idx, 1)
              onChange(vals)
            }} />
          </div>
        </div>
      })}
    </div>
    <div className="mt-3 input-wrap">
      <input
        type="text"
        className="form-control me-3"
        placeholder={`${prompt} (press Enter)`}
        value={text}
        onChange={e => setText(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onChange([...values, text])
            setText('')
          }
        }}
      />
      <button
        className="btn btn-primary"
        disabled={!text}
        onClick={() => {
          onChange([...values, text])
          setText('')
        }}
      >
        Add
      </button>
    </div>
  </div>
}
