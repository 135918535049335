import { errorToString, IntegrationLabels } from '@oneethos/shared'
import react, { useEffect, useState } from 'react'
import { useApi, useAppState } from "../hooks"
import api from '../api-client'
import { toast } from 'react-toastify'
import { Spinner } from '@fluentui/react'

export const DataSync = () => {
  const [loading, setLoading] = useState(false)
  const { tenant } = useAppState()
  const label = IntegrationLabels[tenant.config.consumerApplicationIntegration]

  return <div className="data-sync">
    <div className="buttons">
      <button
        className="btn btn-danger"
        disabled={loading}
        onClick={() => {
          setLoading(true)
          api.post('/sync')
            .then(() => toast.success('sync initiated, it may take a few moments...'))
            .catch(ex => toast.error(errorToString(ex)))
            .finally(() => setTimeout(() => setLoading(false), 3_000))
        }}
      >{loading ? <Spinner /> : `Sync from ${label}`}</button>
    </div>
  </div>
}

