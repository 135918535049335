import React, { useState } from 'react'
import api from '../api-client'
import { toast } from 'react-toastify'
import { Spinner } from '@fluentui/react'

export const NewInstallerForm = ({ onAdd, installerID }) => {
  const [submitting, setSubmitting] = useState(false)

  const [installer, setInstaller] = useState({
    email: '',
    access: 'rep'
  })

  return <div className="new-installer-form">
    <h3>Add Representative</h3>
    <div className="form-group">
      <label>Email</label>
      <input
        type="text"
        value={installer.email}
        className="form-control"
        onChange={ev => setInstaller({
          ...installer,
          email: ev.target.value
        })}
      />
    </div>
    <div className="form-group">
      <label>Access Level</label>
      <select
        value={installer.access}
        className="form-select"
        onChange={ev => setInstaller({
          ...installer,
          access: ev.target.value
        })}
      >
        <option value="rep">Representative</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <div className="buttons">
      {submitting ? <Spinner /> : <button type="button"
        className="btn btn-primary"
        onClick={() => {
          setSubmitting(true)
          api.post(`/installers/${installerID}`, installer)
            .then(_i => {
              setInstaller({ email: '', access: 'rep' })
              toast.success('New Representative Invite sent!')
              onAdd()
            })
            .catch(ex => toast.error(ex.error || ex.message, {
              autoClose: false
            }))
            .finally(() => setSubmitting(false))
        }}
      >
        Email Invitation
      </button>}
    </div>
  </div>
}

