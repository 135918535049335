import React from 'react'
import './progress-bar.scss'

type ProgressBarProps = {
  progress: number
  total: number
  label: string
}

export const ProgressBar = ({ progress, total, label }: ProgressBarProps) => {
  const percent = Math.round(progress / total * 100)

  return (
    <div className="pb-wrap">
      <label>{label}</label>
      <div className="pb-cont">
        <div className="progress">
          <div className="progress-bar" style={{ width: `${percent}%` }}></div>
        </div>
        <div className="pb-pct">{progress}%</div>
      </div>
    </div>
  )
}