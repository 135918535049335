import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Section } from '../components'
import { LuSearchX } from "react-icons/lu"

export const SearchResultsPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const installers = location.state.installers

  return (
    <>
      <Section>
        {installers.length > 0 ? (
          <>
            <div className="section-title">
              <h2 className="margin-bottom-0">Search Results</h2>
            </div>
            {installers.map((installer) => (
              <ul className="list-group"  >
                <li
                  key={installer._id}
                  onClick={() => navigate(`/installer-admin/${installer?.installerID}`)}
                  style={{ cursor: 'pointer' }}
                  className="list-group-item mb-3"
                >
                  <p><b>Installer ID:</b> {installer.installerID}</p>
                  <p className="text-capitalize"><b>Name:</b> {installer.name ? installer.name : 'Not provided'}</p>
                  <p><b>Email:</b> {installer.email}</p>
                </li>
              </ul>
            ))}
          </>
        ) : (
          <div className='flex-column justify-content-center text-center'>
            <LuSearchX size={40} className='mb-2' />
            <p className='fs-5'>We couldn't find anything matching your search.</p>
            <p>Try again with a different input.</p>
          </div>
        )}
      </Section>
    </>
  )  
}

export default SearchResultsPage
