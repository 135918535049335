import { Dispatch } from "react"
import { createContext } from "react"
import { InstallerAction, InstallerRegistration } from "./reducers/registrationReducer"
import { InstallersStatusConfig, InstallersStatusConfigAction } from "./reducers/installersStatusReducer"
import { TenantAction, TenantState } from "./reducers/tenantReducer"

export interface ApplicationState {
  tenant: TenantState
  registration: InstallerRegistration
  installersStatusConfig: InstallersStatusConfig
}

export interface Context {
  state: ApplicationState
  dispatch: Dispatch<InstallerAction | InstallersStatusConfigAction | TenantAction>
}

const initialState = {
  tenant: {
    loading: false,
    error: undefined,
    config: undefined
  },
  registration: {
    token: null,
    installer: undefined,
    loading: false,
    deadline: undefined,
    authError: undefined
  },
  installersStatusConfig: {
    loading: false,
    error: undefined,
    list: undefined,
    dict: undefined
  }
}

const dispatch = () => { return }

export const AppContext = createContext<Context>({
  state: initialState,
  dispatch: dispatch
})