import { Incentive, ITenant } from "@oneethos/shared"
import { TenantAction } from "../reducers/tenantReducer"

export const actions = {
  loadTenant(): TenantAction {
    return { type: 'LOAD_TENANT' }
  },

  setTenant(config: Partial<ITenant>): TenantAction {
    return { type: 'SET_TENANT', data: config }
  },

  setTenantError(err: string): TenantAction {
    return { type: 'SET_TENANT_ERROR', data: err }
  },

  setCobranding(incentive: Incentive): TenantAction {
    return { type: 'SET_COBRANDING', data: incentive }
  }
}

export default actions