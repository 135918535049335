import { PasswordInput, RoutingNumberInput, TextInput } from "../forms"

export const FundingConfig = ({ config, setConfig }) => {
  return <div>
    <p>Used for generating NACHA files</p>
    <div className="form-group">
      <label>Originating Depository Financial Institution (ODFI) Routing Number</label>
      <RoutingNumberInput
        value={config.achOdfiRoutingNo}
        onChange={val => setConfig({ ...config, achOdfiRoutingNo: val })}
      />
    </div>

    <div className="form-group">
      <label>Originating Depository Financial Institution (ODFI) Company Name</label>
      <TextInput
        value={config.achOdfiName}
        onChange={val => setConfig({ ...config, achOdfiName: val })}
      />
    </div>
    <div className="form-group">
      <label>Funding Account Number</label>
      <PasswordInput
        value={config.achOdfiAccountNo}
        onChange={val => setConfig({ ...config, achOdfiAccountNo: val })}
      />
    </div>
    <div className="form-group">
      <label>Funding Account Type</label>
      <select value={config.achOdfiAccountType}
        className="form-select"
        onChange={ev => setConfig({ ...config, achOdfiAccountType: ev.target.value })}>
        <option></option>
        <option value="checking">Checking</option>
        <option value="savings">Savings</option>
      </select>
    </div>
  </div>
}