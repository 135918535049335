import {
  InstallersStatusConfig,
  InstallersStatusConfigAction
} from "../reducers/installersStatusReducer"

export const actions = {
  loadInstallersStatusConfig(): InstallersStatusConfigAction {
    return { type: 'LOAD_INSTALLERS_STATUS_CONFIG' }
  },

  setInstallersStatusConfig(config: InstallersStatusConfig): InstallersStatusConfigAction {
    return { type: 'SET_INSTALLERS_STATUS_CONFIG', data: config }
  },

  setInstallersStatusConfigError(err: string): InstallersStatusConfigAction {
    return { type: 'SET_INSTALLERS_STATUS_CONFIG_ERROR', data: err }
  }
}

export default actions