import { Spinner } from '@fluentui/react'
import { useContext, useEffect, useState } from 'react'
import api from '../api-client'
import { toast } from 'react-toastify'
import actions from '../actions'
import { AppContext } from '../appContext'
import { StatusEmailFreq, StatusUpdatePref, StatusUpdateType } from '@oneethos/shared'

export const CommunicationPreferences = () => {
  const { dispatch, state: { registration: { installer } } } = useContext(AppContext)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [statusUpdatePref, setStatusUpdatePref] = useState(installer?.statusUpdatePref || 'email')
  const [statusUpdateType, setStatusUpdateType] = useState(installer?.statusUpdateType || 'all')
  const [statusEmailFreq, setStatusEmailFreq] = useState(installer?.statusEmailFreq || 'weekly')
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    // clear errors on input change
    setError('')
  }, [statusUpdatePref, statusEmailFreq])

  return <div className="installer-profile">
    <div className="section-title">
      <h2 className="margin-bottom-0">Communication Preferences</h2>
    </div>
    <div className="form-group">
      <label>Receive Project Status Updates</label>
      <select
        className="form-select"
        onChange={ev => {
          setStatusUpdatePref(ev.target.value as StatusUpdatePref)
          setButtonDisabled(false)
        }}
        value={statusUpdatePref}
      >
        <option value="sms-and-email">SMS and Email</option>
        <option value="sms">SMS</option>
        <option value="email">Email</option>
        <option value="none">Do not send me notifications for status changes</option>
      </select>
    </div>
    <div className="form-group">
      <label>Project Status Update Types</label>
      <select
        className="form-select"
        onChange={ev => {
          setStatusUpdateType(ev.target.value as StatusUpdateType)
          setButtonDisabled(false)
        }}
        value={statusUpdateType}
      >
        <option value="all">All updates</option>
        <option value="requires-installer-action">Only when action is needed by installer</option>
      </select>
    </div>
    <div className="form-group">
      <label>Project Status Email Frequency</label>
      <select
        className="form-select"
        onChange={ev => {
          setStatusEmailFreq(ev.target.value as StatusEmailFreq)
          setButtonDisabled(false)
        }}
        value={statusEmailFreq}
      >
        <option value="daily">Daily (Mon-Fri)</option>
        <option value="weekly">Weekly (Monday)</option>
        <option value="monthly">Monthly (1st of Month)</option>
        <option value="hourly">Hourly</option>
        <option value="never">Never</option>
      </select>
    </div>
    <div>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {saving ? <Spinner /> : <div className="buttons">
        <button
          type="button"
          disabled={buttonDisabled}
          className="btn btn-primary"
          onClick={() => {
            setSaving(true)
            setError('')
            api.patch('/installers/me', {
              statusEmailFreq,
              statusUpdatePref,
              statusUpdateType
            }).then(res => {
              dispatch(actions.setInstaller(res))
              toast.success('Preferences updated!')
              setButtonDisabled(true)
            }).catch(ex => {
              setError(ex.message || ex.error)
            }).finally(() => setSaving(false))
          }}
        >Update Preferences</button>
      </div>
      }
    </div>
  </div>
}

export default CommunicationPreferences