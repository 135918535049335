import { dollars, dollarsToNum, LoanApplication, PreSubmitStatusOrder } from "@oneethos/shared"

export const formDataToLoanApp = (formData): LoanApplication => {
  const { ...lapp } = formData

  lapp.customerName = `${lapp.firstName} ${lapp.lastName}`
  if (typeof lapp.loanAmount === 'string') {
    lapp.loanAmount = dollarsToNum(lapp.loanAmount)
  }

  if (typeof lapp.avgMonthlyUtilityBill === 'string') {
    lapp.avgMonthlyUtilityBill = dollarsToNum(lapp.avgMonthlyUtilityBill)
  }

  return lapp
}

export const lappToFormData = (lapp: LoanApplication): Partial<LoanApplication> => {
  return {
    preSubmitStatus: PreSubmitStatusOrder[0],
    // important to initialize these to false to prevent false approvals
    agreesToTermsAndConditions: false,
    agreesToProceedWithHardCreditInquiry: false,
    includeCoBorrower: 'no',
    installedAtDifferentLocation: 'no',
    useAutoDebit: 'yes',
    ...lapp,
    incentive: parseFloat(lapp.incentive?.estPriceWithIncentives) > 0 ? lapp.incentive : undefined,
    loanAmount: dollars(lapp.loanAmount?.toString() || ''),
    avgMonthlyUtilityBill: (
      lapp.avgMonthlyUtilityBill ?
        dollars(lapp.avgMonthlyUtilityBill?.toString()) :
        undefined
    )
  }
}
