import { docTypeDescription, getUploadSlots, StatusData, UploadDocType } from '@oneethos/shared'
import React, { useState } from 'react'
import { useAppState } from '../hooks'
import { DocActionBadge } from './doc-action-badge'
import { BankerDocumentSlot, InstallerUploadForm } from './documents'

export type ProjectRequiredDocumentsProps = {
  project: StatusData
  view: 'review' | 'checklist'
  onChange: (p: StatusData) => void
  onOther: () => void
}

export const ProjectRequiredDocuments = ({
  project,
  onChange,
  onOther,
  view
}: ProjectRequiredDocumentsProps) => {
  const { registration: { installer }, installersStatusConfig: isc } = useAppState()

  if (view === 'review' && installer.isSupport) {
    // build the unique set of slots that may exist across overlapping status configs
    const uploadSlots = getUploadSlots(isc.list)

    return <div className="project-docs">
      <DocActionBadge count={project.docsNeedSupportActionCount} />
      {Object.entries(uploadSlots).map(([uploadType, slots]) => {
        const stepDocs = project.installerDocs[uploadType] || {}
        return <div key={uploadType} className="doc-upload-item">
          <div className="up-label"><b>{docTypeDescription(uploadType as UploadDocType)}</b></div>
          <div>{Object.entries(slots).map(([slot, instructions]) => {
            const doc = stepDocs[slot]
            return <BankerDocumentSlot
              key={slot}
              project={project}
              slotConfig={{ slot, instructions }}
              uploadType={uploadType as UploadDocType}
              document={doc}
              onChange={onChange}
            />
          })}</div>
        </div>
      })}
    </div>
  } else if (view === 'checklist') {
    return <div>
      <InstallerUploadForm
        project={project}
        onProjectChange={onChange}
      />
      <button type="button"
        className="btn btn-primary text-light"
        onClick={onOther}
      >I need to upload other documents</button>
    </div>
  }
}

export default ProjectRequiredDocuments