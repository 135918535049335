import { Section } from "../components"
import { useAppState } from "../hooks"

export const TrainingPage = () => {
  const { tenant } = useAppState()

  return <div className="training-page">
    <Section>
      {tenant.config?.trainingPageUrl ? <iframe
        title="training materials"
        width="100%"
        style={{ minHeight: '350vh' }}
        src={tenant.config?.trainingPageUrl}
      /> : <h3>Training materials not available</h3>}
    </Section>
  </div>
}