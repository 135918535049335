import React, { useState, useEffect, ChangeEvent } from 'react'
import { StatusData } from '@oneethos/shared'
import { IoIosSearch } from "react-icons/io"
import './project-search.scss'
import { Validate } from '@oneethos/shared'

type ProjectSearchProps = {
  list: StatusData[]
  onFilter: (list: StatusData[]) => void
  resetStatusFilter: () => void
}

export const ProjectSearch = ({ list, onFilter, resetStatusFilter }: ProjectSearchProps) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [matches, setMatches] = useState<StatusData[]>([])

  const onchange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value)
    resetStatusFilter()
  }

  useEffect(() => {
    if (!searchInput.trim()) {
      setMatches(list)
      onFilter(list)
      return
    }
  
    const escapedInput = Validate.escapeRegex(searchInput)
    const regexPattern = Validate.createRegexPattern(escapedInput)
  
    try {
      const regex = new RegExp(regexPattern, 'i')
      const result = list.filter(item => {
        return regex.test(item.customerName) || regex.test(item.address) || regex.test(item.email)
      })
      setMatches(result)
      onFilter(result)
    } catch (error) {
      console.error('Invalid regex pattern:', regexPattern)
      setMatches([])
      onFilter([])
    }
  }, [searchInput, setMatches, onFilter])
  
  
  return (
    <div className='mb-3'>
      <div className="search-container" style={{ maxWidth: '350px' }} >
        <label className='search-label'>Search</label>
        <IoIosSearch className="search-icon" size={20} />
        <input
          value={searchInput}
          type="search"
          className="form-control search-input"
          placeholder="search by email, name or address"
          onChange={onchange}
        />
      </div>
    {searchInput && <span className='search-message' >{matches.length} projects match your search</span>}
    </div>
  )
}

export default ProjectSearch
