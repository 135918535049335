
export enum Masks {
  SSN = '•••-••-',
  Phone = '•••-•••-',
  Default = '•••••••'
}

export const mask = (s: string, show = 3, chars?: string): string => {
  if (!s) return ''

  if (!chars) {
    if (show === 0) {
      return s.replace(/./g, '•')
    } else {
      const lead = s.slice(0, -show).replace(/./g, '•')
      const tail = s.slice(-show)
      return `${lead}${tail}`
    }
  } else {
    return chars + s.slice(-show)
  }
}

export const isMasked = (s: string): boolean => {
  return s?.includes('•')
}
