import react from 'react'

export const ApplicationMenu = ({ maxStep, onNav, currentStep, statuses }) => {
  const pos = statuses.findIndex(s => s === maxStep)
  return <div className="caf-menu">
    {statuses.map((step, i) => <div
      key={i}
      className={['step', i <= pos ? 'active' : '', currentStep === step ? 'current' : ''].join(' ')}
      onClick={() => {
        if (i <= pos) {
          onNav(statuses[i])
        }
      }}
      style={i <= pos ? { cursor: 'pointer' } : {}}
    >{step}</div>)}
  </div>
}

export default ApplicationMenu