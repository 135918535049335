import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { StorageKey, sha256Hash } from '../lib'
import { getBrowserFingerprintData } from '../browser-fingerprint'

type RequestLoginParams = {
  email: string
  phone?: string
  name?: string
  installerID?: string
  route?: string
}

const initLoginAttempt = () => {
  const browser = getBrowserFingerprintData()
  const attemptId = crypto.randomUUID()
  localStorage.setItem(StorageKey.LoginAttemptId, attemptId)
  return { browser, attemptId }
}

export const requestLoginViaEmail = async ({
  email,
  installerID,
  route
}: RequestLoginParams): Promise<{ message: string }> => {
  const { browser, attemptId } = initLoginAttempt()

  return api.post('/installers/login-with-email', {
    email, 
    installerID, 
    route, 
    browser,
    attemptId
  })
}

export const requestRegistrationViaEmail = async({
  email,
  phone, 
  name,
  route
}: RequestLoginParams): Promise<{ message: string }> => {
  const { browser, attemptId } = initLoginAttempt()

  return api.post('/installers/register', {
    email,
    phone,
    name,
    route,
    browser,
    attemptId
  })
}

type LoginButtonProps = {
  submitting: boolean
  email: string
  installerID?: string
  route?: string          // path to redirect user after login
  setSubmitting: (v: boolean) => void
  onError: (ex: string | Error & { code?: string, error?: string }) => void
  onSuccess: (s: string) => void
}

export const LoginButton = ({
  submitting,
  email,
  installerID,
  route,
  setSubmitting,
  onError,
  onSuccess
}: LoginButtonProps) => {
  return <button type="button"
    className="btn btn-primary"
    disabled={submitting}
    onClick={() => {
      setSubmitting(true)
      requestLoginViaEmail({ email, installerID, route }).then(res => {
        onSuccess(res.message)
      }).catch(onError).finally(() => setSubmitting(false))
    }}
  >{submitting ? <Spinner /> : 'Email my Login Link'}</button>
}