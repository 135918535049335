import { useState, useEffect, useCallback } from "react"
import { Installer } from "@oneethos/shared"
import { InstallerAdminRow, NewInstallerForm, Section } from "../components"
import { useAppState } from "../hooks"
import { Navigate, useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import api from '../api-client'
import { PlaidAuthSelect, OrgAdmin } from "../components"

export const InstallerAdminPage = () => {
  const [error, setError] = useState('')
  const [installers, setInstallers] = useState([])
  const params = useParams()
  const { registration: { installer } } = useAppState()

  const installerID = params['installerID'] || installer.installerID

  const fetchInstallers = useCallback(() => {
    const search = new URLSearchParams({ installerID })

    const url = `/installers?${search.toString()}`
    api.get(url).then(_installers => {
      setInstallers(_installers)
    }).catch(ex => { setError(ex.error || ex.message) })
  }, [installerID])

  const patch = useCallback((i: Installer) => {
    api.patch(`/installers/${i._id}`, i)
      .then(() => {
        toast.success('Installer update saved', { autoClose: 1000 })
        fetchInstallers()
      })
      .catch(ex => { setError(ex.error || ex.message) })
  }, [fetchInstallers])

  useEffect(() => {
    if (installer?.isAdmin) {
      fetchInstallers()
    }
  }, [fetchInstallers, installer?.isAdmin])

  if (!installer?.isAdmin) {
    return <Navigate to="/projects" />
  }

  return <div className="installer-admin-page">
    <Section>
      <div className="section-title">
        <h2 className="margin-bottom-0">
          {['super_admin', 'support'].includes(installer.access) ? <>
            <span>{installerID}</span> |&nbsp;
          </> : null}
          Installer Admin
        </h2>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </div>

      <div className="table-list-wrap">
        <table className="table">
          <tbody>
            <tr>
              <th>Email</th>
              <th>Access Level</th>
              <th>Status</th>
              <th>Active</th>
            </tr>
            {installers.map(i => <InstallerAdminRow
              self={installer}
              key={i._id}
              installer={i}
              onChangeAccess={access => patch({ ...i, access })}
              onToggleAccess={() => patch({ ...i, active: !i.active })}
            />)}
          </tbody>
        </table>
      </div>
    </Section>
    <Section>
      <NewInstallerForm
        onAdd={() => fetchInstallers()}
        installerID={installerID}
      />
    </Section>
    {installer.isSupport ? <Section>
      <OrgAdmin installerID={installerID} />
    </Section> : null}
  </div>
}

export default InstallerAdminPage