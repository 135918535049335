import React from 'react'
import { IconType } from 'react-icons/lib'
import './radio-toggle.scss'

type Option = {
  value: string
  label?: string
  Icon?: IconType
}

type RadioToggleProps = {
  value: string
  options: Option[]
  onChange: (value: string) => void
}

export const RadioToggle = ({
  value,
  options,
  onChange
}: RadioToggleProps) => {
  return <div className="radio-toggle">
    {options.map(o => (
      <a className={`btn mb-2 btn-light ${value === o.value ? 'active' : ''}`}
        style={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={ev => {
          ev.preventDefault()
          onChange(o.value)
        }}
        key={o.value}>
        {o.Icon ? <o.Icon className="me-1" /> : null}
        {o.label || o.value}
      </a>
    ))}
  </div>
}

export default RadioToggle