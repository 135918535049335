import { Spinner } from '@fluentui/react'
import { useContext, useEffect, useState } from 'react'
import api from '../api-client'
import { useUtmQuery } from '../hooks'
import InstallerService from '../services/installerService'
import actions from '../actions'
import { AppContext } from '../appContext'
import { Section } from './section'
import { useNavigate } from 'react-router-dom'
import { StorageKey } from '../lib'
import { getBrowserFingerprintData } from '../browser-fingerprint'

export const InstallerProfile = () => {
  const { dispatch, state: { registration: { installer } } } = useContext(AppContext)
  const registered = !!installer
  const [updateDisabled, setUpdateDisabled] = useState(false)
  const [name, setName] = useState(installer?.name || '')
  const [email, setEmail] = useState(installer?.email || '')
  const [installerID, setInstallerID] = useState(installer?.installerID || '')
  const [phone, setPhone] = useState(installer?.phone || '')
  const [saving, setSaving] = useState(false)

  const [requireInstallerID, setRequireInstallerID] = useState(false)
  const [info, setInfo] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const navigate = useNavigate()
  const { branchId, campaign } = useUtmQuery()

  useEffect(() => {
    // clear errors on input change
    setError('')
    setUpdateDisabled(
      name === installer?.name &&
      email?.toLowerCase().trim() === installer?.email?.toLowerCase().trim() &&
      phone?.toLowerCase().trim() === installer?.phone?.toLowerCase().trim()
    )
  }, [name, email, phone])

  const updateRegistration = (attemptId: string) => {
    return api.patch('/installers/me', {
      name,
      phone,
      browser: getBrowserFingerprintData(),
      attemptId
    }).then(res => {
      // changing info when logged in may require reverification, but we need to avoid
      // clearing the token until after the api call
      if (res.requiresVerification) {
        InstallerService.clearToken()
        dispatch(actions.logout())
        navigate('/verify-email')
      } else {
        setSuccess(res.message)
      }
    })
  }

  const register = (attemptId: string) => {
    return api.post('/installers/register', {
      name,
      phone,
      browser: getBrowserFingerprintData(),
      attemptId,
      branchId,
      campaign,
      email,
      installerID
    }).then(res => {
      setSuccess(res.message)
      navigate('/verify-email')
    }) 
  }

  return <Section>
    <div className="installer-profile">
      <div className="section-title">
        <h2 className="margin-bottom-0">My Profile</h2>
      </div>
      <div>
        {registered ? <div className="alert alert-success" title="">
          You are registered as <b>{installer.email}</b>
        </div> : null}
        <p>
          To {registered ? 'update your information' : 'complete registration'}, you will need to
          verify both your email address and your phone number.
        </p>
        <div className="form-group">
          <label>Name</label>
          <input type="text"
            className="form-control"
            value={name}
            autoComplete='off'
            onChange={ev => setName(ev.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="text"
            value={email}
            disabled={registered}
            className="form-control"
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input type="text"
            value={phone}
            className="form-control"
            onChange={ev => setPhone(ev.target.value.replace(/\D/g, ''))}
          />
          <p className="small">10 digits, no punctuation</p>
        </div>
        {requireInstallerID ? <div className="form-group">
          <label>Installer ID</label>
          <input type="text"
            value={installerID}
            className="form-control"
            onChange={ev => setInstallerID(ev.target.value)}
          />
        </div> : null}
        {info ? <div className="alert alert-info">{info}</div> : null}
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {success ? <div className="alert alert-success">{success}</div> : (
          saving ? <Spinner /> : <div className="buttons">
            <button
              type="button"
              className="btn btn-primary"
              disabled={updateDisabled}
              onClick={() => {
                setSaving(true)
                setError('')

                const attemptId = crypto.randomUUID()
                localStorage.setItem(StorageKey.LoginAttemptId, attemptId)
                dispatch(actions.setAuthError(''))
                const action = registered ? updateRegistration : register

                // send a login code in case they're already registered; worst case this code
                // will linger in their browser and be reset at some point when they initialize
                // another login
                action(attemptId).catch(ex => {
                  if (ex.code === 'INSTALLER_ID_REQUIRED') {
                    setRequireInstallerID(true)
                    setInfo('Installer ID Required')
                  } else {
                    setError(ex.message || ex.error)
                  }
                }).finally(() => setSaving(false))
              }}
            >{registered ? 'Update Information' : 'Register'}</button>
          </div>
        )}
      </div>
    </div>
  </Section>
}

export default InstallerProfile