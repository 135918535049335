import { Reducer } from "react"
import { registrationReducer, InstallerAction } from "./registrationReducer"
import { installersStatusReducer, InstallersStatusConfigAction } from "./installersStatusReducer"
import { TenantAction, tenantReducer } from "./tenantReducer"

type Action = InstallerAction | InstallersStatusConfigAction | TenantAction

const combineReducers = (slices: { [key: string]: Reducer<any, Action> }) => (
  prevState: any,
  action: Action
) =>
  Object.keys(slices).reduce(
    (nextState, nextProp) => ({
      ...nextState,
      [nextProp]: slices[nextProp](prevState[nextProp], action)
    }),
    prevState
  )

export default combineReducers({
  tenant: tenantReducer,
  registration: registrationReducer,
  installersStatusConfig: installersStatusReducer
})
