
export const isMongooseError = ex => {
  if (typeof ex.error === 'object') {
    for (const o of Object.values(ex.error)) {
      if (typeof o === 'object') {
        const keys = Object.keys(o)
        if (keys.includes('name') && keys.includes('reason') && typeof o['reason'] === 'object') {
          return true
        }
      }
    }
  }

  return false
}

export const errorToString = ex => {
  let msg

  if (typeof ex === 'string') {
    msg = ex
  } else if (isMongooseError(ex)) {
    msg = Object.keys(ex.error).map(key => `Problem with field ${key}.`).join(' ')
  } else {
    msg = ex.message || ex.error || JSON.stringify(ex)
  }

  return msg
}