import { Spinner } from "@fluentui/react"
import { errorToString } from "@oneethos/shared"
import { useState } from "react"
import { toast } from "react-toastify"
import { Link } from "."

type ActionLinkProps = {
  label: string
  action: () => Promise<void>
  doneLabel: string
}

export const ActionLink = ({
  label,
  action,
  doneLabel
}: ActionLinkProps) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  if (loading) {
    return <Spinner />
  }

  return <div className="send-loan-docs">
    {done ? <div className="badge text-bg-secondary">{doneLabel}</div> : <>
      <Link
        href='#'
        onClick={ev => {
          ev.preventDefault()
          setLoading(true)
          action().then(() => {
            setDone(true)
          }).catch(ex => toast.error(errorToString(ex)))
            .finally(() => setLoading(false))
        }}
        label={label}
      />
    </>}
  </div>
}

export default ActionLink
