import { StatusData, dateAndTime, docTypeDescription } from "@oneethos/shared"
import { DocLink } from "../documents"

type DocHistoryProps = {
  project: StatusData
}

export const DocumentUploadHistory = ({ project }: DocHistoryProps) => {
  return <div className="project-docs">
    {!project.uploads.length ? <div className="alert alert-secondary">This project does not have any documents</div> : (
      <div className="table-list-wrap">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Upload Type</th>
              <th>Date</th>
              <th>Uploaded By</th>
              <th>Documents</th>
            </tr>
          </thead>
          <tbody>
            {project.uploads.map((u, idx) => <tr key={idx}>
              <td>
                {docTypeDescription(u.uploadType)}
                {u.notes ? <div className="comment"> {u.notes} </div> : null}
              </td>
              <td>{dateAndTime(u.timestamp)}</td>
              <td>{u.uploadedBy}</td>
              <td style={{ maxWidth: '350px' }}>
                {u.documents.map((doc, i) => (
                  <div key={i}>
                    <div className="doc-main">
                      <DocLink
                        doc={doc}
                        project={project}
                        slotConfig={{
                          slot: doc.docType,
                          instructions: ''
                        }}
                      />
                    </div>
                  </div>
                ))}
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    )}
  </div>
}

export default DocumentUploadHistory