import { useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"

export const EquifaxDitReport = ({ report }) => {
  const [expand, setExpand] = useState(false)

  return <div className="list-group-item credit-report">
    <h5>DIT</h5>
    <div>CorrelationID: {report.correlationId}</div>
    <div>Decision: {report.decision}</div>
    <div>Reference: {report.referenceTransactionId}</div>

    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Details {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="ppt-test">
      <thead>
        <tr>
          <th>Detail</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {report.details?.map((d, i) => <tr key={i}>
          <td>{d.key}</td>
          <td>{d.value}</td>
        </tr>)}
      </tbody>
    </table> : null}
  </div>
}

export default EquifaxDitReport