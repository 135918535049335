import React, { useState } from 'react'
import { SubformProps, Validation } from "."
import { Validate } from '@oneethos/shared'
import EmploymentForm from './employment-form'
import { Spinner } from '@fluentui/react'

export const Employment = ({ schema, formData, update, save, saving }: SubformProps) => {
  const [validation, setValidation] = useState<Record<string, string>>({})

  const validate = () => {
    const results = Validate.validateFields(schema, formData, [
      'employerName',
      'occupation',
      'yearsAtEmployer',
    ])

    if (formData.yearsAtEmployer < 2 && !formData.previousEmployment) {
      results['previousEmployment'] = 'Please add previous employment'
    }

    return results
  }

  return <>
    <h2>Employment Information</h2>
    <EmploymentForm
      employment={formData}
      onChange={update}
    />
    <div>
      <button
        type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={() => {
          const v = validate()
          setValidation(v)
          if (Object.keys(v).length === 0) {
            save()
          }
        }}
      >{saving ? <Spinner /> : 'Continue'}</button>
    </div>
    {validation ? (
      <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
    ) : null}
  </>
}

export default Employment