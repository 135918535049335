import { MiniSection } from "./section"

type AuthErrorProps = {
  authError: string
  message?: string
}

export const AuthError = ({ authError, ...props }: AuthErrorProps) => {
   let message
  if (authError === 'TokenExpiredError') {
    message = <div>Your session has expired, please log back in.</div>
  } else if (authError === 'LOGIN_REQUIRED') {
    message = <div>Login is required to view the requested page.</div>
  } else if (authError === 'USER_CONFLICT') {
    message = <div>
      You cannot access the attempted link using your current account. You may try logging out 
      or you may need to login with a different email address.
    </div>
  } else if (authError === 'UNKNOWN_DEVICE') {
    message = <div><b>Alert!</b> We don't recognize this device as being associated with your account. To protect your security, please log back in to associate this device with your account.</div>
  } else if (authError === 'Failed to fetch') {
    message = <div>
      There was a problem communicating with the server. This means we're either working on the problem and
      it should be resolved shortly, or there may be an issue with your network connection.
    </div>
  }

  return <MiniSection type="danger">
    {message ? <div>{message}</div> : <>
      {/* <div>There was an error initializing the page. If the problem persists, please contact support.</div> */}
      <div><b>Error: </b>{authError}</div>
    </>}
  </MiniSection>
}

export default AuthError
