import React, { useState } from 'react'
import { errorToString, Installer } from '@oneethos/shared'
import { useLocalStorage } from '../hooks'
import { StorageKey } from '../lib'
import { FaCheck, FaPhone, FaQuestionCircle, FaToggleOff, FaToggleOn, FaUserSecret } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { TbMailForward } from 'react-icons/tb'
import { MdLockReset } from 'react-icons/md'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { TableCellSelect } from './forms'
import api from '../api-client'

const getStatus = (i: Installer): string => {
  if (i.active === false) {
    return 'Inactive'
  } else if (i.emailVerified && i.phoneVerified) {
    return 'Registered'
  } else {
    return 'Pending Registration'
  }
}

type Props = {
  installer: Installer
  onChangeAccess: (access: string) => void
  onToggleAccess: () => void
  self: Installer
}

const formatPhone = (phone: string) => {
  if (!phone) return ''
  if (/^\d{10}$/.test(phone)) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
  }
  return phone
}

const ResetMfa = ({ installer, onComplete }) => {
  const [state, setState] = useState('')

  if (state === 'reset') {
    return null
  }

  if (state === 'resetting') {
    return <Spinner size={SpinnerSize.small} />
  }

  return <span title="reset mfa"
    style={{
      cursor: 'pointer',
    }}
    onClick={() => {
      setState('resetting')
      const notes = window.prompt(`Please add notes to confirm you have verified resetting multi-factor authorization for ${installer.email}.`)
      if (notes?.length > 10) {
        api.post(`/installers/${installer._id}/reset-mfa`, { notes })
          .then(() => {
            toast.success(
              `Multifactor auth has been reset for ${installer.email}; please instruct them to re-register with their new phone number`,
              { autoClose: false }
            )
            setState('reset')
            onComplete()
          })
          .catch(ex => {
            toast.error(`error resetting multi-factor auth: ${errorToString(ex)}`)
          })
      } else {
        setState('')
        toast.error(`Please add enough notes to confirm you have verified the authorization change for ${installer.email}.`)
      }
    }}>
    <MdLockReset color="#666" />
  </span>
}

const SetImpersonate = ({ installer }) => {
  const [, setImpersonate] = useLocalStorage(StorageKey.ImpersonateUserId, undefined)

  return <span
    style={{ cursor: 'pointer' }}
    onClick={() => {
      setImpersonate(installer._id)
      window.location.reload()
    }}
  >
    <FaUserSecret color="#666" title="impersonate user" />
  </span>
}

export const InstallerAdminRow = ({ installer, onChangeAccess, onToggleAccess, self }: Props) => {
  const [sending, setSending] = useState('')
  const [_installer, setInstaller] = useState(installer)

  const status = getStatus(_installer)

  // TODO: 🙄 need to normalize on .id or ._id
  const isSelf = installer.id === self._id
  const disableEdit = !['rep', 'admin'].includes(installer.access) || isSelf

  return (
    <tr className="installer-row">
      <td>
        <div className="email">
          {installer.email}
          <span className="installer-actions">
            {(!isSelf && status === 'Registered' && self.isSupport && installer.isInstaller)
              ? <ResetMfa
                installer={installer}
                onComplete={() => setInstaller({
                  ..._installer,
                  emailVerified: false,
                  phoneVerified: false,
                } as Installer)} />
              : null
            }
            {(installer.active !== false && self.access === 'super_admin') ? <SetImpersonate installer={installer} /> : null}
          </span>
        </div>
        {installer.name && 
          <div style={{ fontSize: '0.8em' }} className="text-secondary"> 
            {installer.name}
          </div>
        }
        <div style={{ fontSize: '0.8em' }} className="text-secondary">
          {formatPhone(installer.phone)}
        </div>
      </td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TableCellSelect
            value={installer.access || 'rep'}
            disabled={disableEdit}
            options={[
              { value: 'rep', label: 'Representative' },
              { value: 'admin', label: 'Admin' },
              { value: 'primary_admin', label: 'Primary Admin', disabled: true }
            ]}
            onSelect={onChangeAccess}
          />
          {disableEdit ? (
            <span
              style={{ marginLeft: '0.5em' }}
              title="Access for this user can only be changed by contacting your administrator">
              <FaQuestionCircle color="#aaa" size={15} />
            </span>
          ) : null}
        </div>
      </td>
      <td>
        <div className="flex flex-row">
          {status}&nbsp;&nbsp;{status === 'Pending Registration' ? <>
            {sending === '' ? <span title="resend invite"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (window.confirm(`Resend invitation to ${installer.email}?`)) {
                  setSending('sending')
                  api.post(`/installers/${installer._id}/resend-invite`, {})
                    .then(() => {
                      toast.success('successfully resent invite')
                      setSending('sent')
                    })
                    .catch(ex => {
                      toast.error(ex.error || ex.message)
                      setSending('')
                    })
                }
              }}>
              <TbMailForward color='#aaa' />
            </span> : null}
            {sending === 'sent' ? <FaCheck color='#aaa' /> : null}
            {sending === 'sending' ? <Spinner size={SpinnerSize.small} /> : null}
          </> : null}
        </div>
      </td>
      <td>
        {disableEdit ? null : (
          <span className="tbl-btn" onClick={onToggleAccess}>
            {installer.active !== false ? <FaToggleOn color='green' /> : <FaToggleOff color='#ccc' />}
          </span>
        )}
      </td>
    </tr>
  )
}
