import React, { useEffect, useState } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { withApplicationInsights } from '../../components/telemetry'
import jwtDecode from 'jwt-decode'

type FormState = {
  uploadType: string
  documents: Record<string, File>
  address?: string
  id: string
}

export const DocUploadPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('t')

  let projectId, error
  if (token) {
    try {
      const payload = jwtDecode(token) as FormState
      payload.documents = {}
      projectId = payload.id
    } catch (ex) {
      // noop; if there's a problem with the token we just fallback to the form 
      // that requires more input
      error = ex
    }
  }

  if (projectId) {
    return <Navigate to={`/project/${projectId}`} />
  }

  return <div className="section">
    <div className="alert alert-danger">
      This link has expired or you may not be able to access this page.
    </div>
    <Link to={"/projects"}>My Portal</Link>
  </div>
}

export default withApplicationInsights(DocUploadPage, 'DocUploadPage')