import { useEffect, useState } from 'react'
import { SubformProps } from "."
import { uploadFile, fileToObj } from '../../services/fileUploadService'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { getIncomeDocErrorsMultiUpload, LoanApplication, SolarDoc } from '@oneethos/shared'
import BorrowerDocUpload from './borrower-doc-upload'

const normalizeDocStructure = (array) => {
  const result = array.reduce((acc, doc) => {
      const existingDocSet = acc.find(set => set.docType === doc.docType)
      if (existingDocSet) {
          existingDocSet.documents.push(doc)
      } else {
          acc.push({ docType: doc.docType, documents: [doc] })
      }        
      return acc 
  }, [])
  return result 
}

export const IncomeVerification = ({ formData, update, save, onError }: SubformProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [showAddDocButton, setshowAddDocButton] = useState(false)
  const [showCoDocButton, setshowCoDocButton] = useState(false)
  const [docsArray, setDocsArray] = useState([])
  const [coDocsArray, setCoDocsArray] = useState([])

  const {
    includeCoBorrower,
  } = formData

  useEffect(() => {
    if(formData.incomeVerificationDocuments.length > 0) {
      setDocsArray(normalizeDocStructure(formData.incomeVerificationDocuments))
      setshowAddDocButton(true)
    }
    if(formData.coIncomeVerificationDocuments.length > 0) {
      setCoDocsArray(normalizeDocStructure(formData.coIncomeVerificationDocuments))
      setshowCoDocButton(true)
    }
  }, [])

  const hasDocs = docsArray.some(docSet => docSet.documents?.length)
  const hasCoDocs = coDocsArray.some(docSet => docSet.documents?.length)

  const errors = getIncomeDocErrorsMultiUpload(formData as LoanApplication, docsArray, coDocsArray)

  const handleAddBorrowerDocUpload = (isCoBorrower = false) => {
    const newDoc = { docType: '', documents: [] }
    if (isCoBorrower) {
      setCoDocsArray([...coDocsArray, newDoc])
      update({
        ...formData,
        coIncomeVerificationDocuments: [...coDocsArray, newDoc]
      })
    } else {
      setDocsArray([...docsArray, newDoc])
      update({
        ...formData,
        incomeVerificationDocuments: [...docsArray, newDoc]
      })
    }
  }

  const handleDocTypeChange = (ev, index, isCoBorrower, isFirstDocument = false) => {
    const borrowerDocsArray = isCoBorrower ? coDocsArray : docsArray
    const updateBorrowerDocsState = isCoBorrower ? setCoDocsArray : setDocsArray
    const formDataKey = isCoBorrower ? 'coIncomeVerificationDocuments' : 'incomeVerificationDocuments'

    const targetIndex = isFirstDocument ? 0 : index + 1
    if (isFirstDocument && borrowerDocsArray.length === 0) {
      updateBorrowerDocsState([{ docType: ev.target.value, documents: [] }])
    } else {
      updateBorrowerDocsState(borrowerDocsArray.map((d, i) => {
        return i === targetIndex ? { ...d, docType: ev.target.value, documents: [] } : d
      }))
    }
  
    update({
      ...formData,
      [formDataKey]: borrowerDocsArray.map((d, i) => {
        return i === targetIndex ? { ...d, docType: ev.target.value, documents: [] } : d
      })
    })
  }

  const handleDocUpload = (isCoBorrower, index, docs) => {
    const updatedDocs = isCoBorrower ? coDocsArray : docsArray
    const updatedDocSet = updatedDocs.map((d, i) => {
      return i === index ? { ...d, documents: docs } : d
    })
  
    if (isCoBorrower) {
      setCoDocsArray(updatedDocSet)
      setshowCoDocButton(updatedDocSet.some(docSet => docSet.documents.length > 0))
      update({ ...formData, coIncomeVerificationDocuments: updatedDocSet })
    } else {
      setDocsArray(updatedDocSet)
      setshowAddDocButton(updatedDocSet.some(docSet => docSet.documents.length > 0))
      update({ ...formData, incomeVerificationDocuments: updatedDocSet })
    }
  }
  
  const handleContinue = async () => {
    try {
      setSubmitting(true)
      const uploadDoc = async (doc: SolarDoc, docType: string) => {
        if (doc._id) return doc
  
        const res = await uploadFile(doc)
        return { ...fileToObj(doc), ...res, docType }
      }
  
      const bDocProm = Promise.all(
        docsArray.flatMap(docSet => 
          docSet.documents.map(doc => uploadDoc(doc, docSet.docType))
        )
      )
  
      const coDocProm = Promise.all(
        coDocsArray.flatMap(docSet => 
          docSet.documents.map(doc => uploadDoc(doc, docSet.docType))
        )
      )
  
      const [borrowerDocs, coBorrowerDocs] = await Promise.all([bDocProm, coDocProm])
      save({
        data: {
          ...formData,
          incomeVerificationDocuments: borrowerDocs,
          coIncomeVerificationDocuments: coBorrowerDocs
        },
        onCatch(ex) {
          onError(typeof ex === 'string' ? ex : (ex.error || ex.message))
          setSubmitting(false)
        }
      })
    } catch (ex) {
      onError(ex.message || ex.error)
      setSubmitting(false)
    }
  }

  if (submitting) {
    return (
      <div style={{ padding: '3em', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
        <Spinner style={{ marginBottom: '2em' }} size={SpinnerSize.large} />
        <div style={{ fontSize: '1.5em', textAlign: 'center' }}>
          Uploading documents and saving your progress...
        </div>
      </div>
    )
  }

  return (
    <>
      <BorrowerDocUpload
        title="Income Verification - Primary Borrower"
        incomeDocuments={docsArray}
        onDocTypeChange={handleDocTypeChange}
        onChangeDoc={handleDocUpload}
        isCoBorrower={false}
      />
      {showAddDocButton && (
        <button type="button" onClick={() => handleAddBorrowerDocUpload(false)} className="btn btn-secondary mb-3">
          Add another document
        </button>
      )}
      {includeCoBorrower === 'yes' && (
        <>
          <BorrowerDocUpload 
            title="Income Verification - Co-Borrower"
            incomeDocuments={coDocsArray}
            onDocTypeChange={handleDocTypeChange}
            onChangeDoc={handleDocUpload}
            isCoBorrower={true}
          />
          {showCoDocButton && (
            <button type="button" onClick={() => handleAddBorrowerDocUpload(true)} className="btn btn-secondary mb-3">
              Add another document
            </button>
          )}
        </>
      )}
      { (hasDocs || hasCoDocs) &&
        (
          errors.length ? <div className="alert alert-info">{errors.map((err, i) => <div key={i}>{err}</div>)}</div> :
            <button
              type="button"
              className="btn btn-primary mb-3 ms-3"
              onClick={handleContinue}
            >
              Continue
            </button>
        )}
    </>
  )
}

export default IncomeVerification
