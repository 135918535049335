import React from 'react'
import DocSelector from './doc-selector'
import { IncomeDocument } from '@oneethos/shared'

interface BorrowerDocUploadProps {
  title: string,
  incomeDocuments: IncomeDocument[],
  onDocTypeChange: (
    ev: React.ChangeEvent<HTMLSelectElement>, 
    index: number,
    isCoBorrower: boolean, 
    isPrimary: boolean) => void,
  onChangeDoc: (isCoBorrower: boolean, index: number, incomeDocuments: IncomeDocument[]) => void,
  isCoBorrower: boolean
}

const BorrowerDocUpload: React.FC<BorrowerDocUploadProps> = ({
  title,
  incomeDocuments,
  onDocTypeChange,
  onChangeDoc,
  isCoBorrower,
}) => {
  return (
    <>
      {isCoBorrower ? <h3>{title}</h3> : <h2>{title}</h2>}
      <DocSelector
        key={0}
        docs={incomeDocuments[0]?.documents}
        docType={incomeDocuments[0]?.docType}
        onDocTypeChange={(ev: React.ChangeEvent<HTMLSelectElement>) => 
          onDocTypeChange(ev, 0, isCoBorrower, true)}
        onChange={(docs: IncomeDocument[]) => onChangeDoc(isCoBorrower, 0, docs)}
      />
      {incomeDocuments.slice(1).map((docSet, index) => (
      <DocSelector
        key={index + 1}
        docs={docSet.documents}
        docType={docSet.docType}
        onDocTypeChange={(ev: React.ChangeEvent<HTMLSelectElement>) => 
          onDocTypeChange(ev, index, isCoBorrower, false)}
        onChange={(docs: IncomeDocument[]) => onChangeDoc(isCoBorrower, index + 1, docs)}
      />
      ))} 
    </>
  )
}

export default BorrowerDocUpload