
export const DocActionBadge = ({ count, variant = "warning" }) => {
  const s = count > 1 ? 's' : ''
  const _s = count > 1 ? '' : 's'

  return count ? <span className={`badge text-bg-${variant}`}>
    {count} Document{s} require{_s} action
  </span> : null
}

