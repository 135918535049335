import { StorageKey } from "../lib"

export class InstallerService {
  static setToken(token: string) {
    localStorage.setItem(StorageKey.InstallerToken, token)
  }

  static clearToken() {
    localStorage.removeItem(StorageKey.InstallerToken)
  }
}

export default InstallerService