import { useState, useEffect, useCallback, useRef } from "react"
import { HeaderLogo, Section } from "../components"
import { useAppState } from "../hooks"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { Link, Navigate } from "react-router-dom"
import { toast } from 'react-toastify'
import api from '../api-client'
import { errorToString, Installer } from "@oneethos/shared"
import { TbMailForward } from 'react-icons/tb'
import { FaQuestionCircle, FaToggleOff, FaToggleOn, FaCheck, FaBan, FaUsers, FaProjectDiagram, FaList } from "react-icons/fa"
import fileDownload from "../lib/file-download"


export const SuperAdminPage = () => {
  const [error, setError] = useState('')
  const [companies, setCompanies] = useState([])

  const { registration: { installer } } = useAppState()

  const hasAccess = ['super_admin', 'support'].includes(installer.access)

  useEffect(() => {
    if (hasAccess) {
      api.get('/orgs').then(res => {
        res.companies.sort((a, b) => b.count - a.count)
        setCompanies(res.companies)
      }).catch(ex => { setError(ex.error || ex.message) })
    }
  }, [hasAccess])

  if (!hasAccess) {
    return <Navigate to="/projects" />
  }

  return <div className="installer-admin-page">
    <Section>
      <button type="button" className="btn btn-primary"
        onClick={() => {
          api.authenticatedFetch('/installers/registration-report', {
            responseType: 'blob'
          }).then(async res => {
            const disposition = res.headers.get('Content-Disposition')
            const filename = disposition.split('filename=')[1].replace(/"/g, '')
            const blob = await res.blob()
            fileDownload(blob, filename)
          }).catch(ex => { console.log(ex); toast.error(errorToString(ex)) })
        }}
      >
        Installer Registrations Report
      </button>
    </Section>

    <Section>
      <h2>Installer Companies</h2>
      {error ? <div className="alert alert-danger">{error}</div> : null}

      <div className="table-list-wrap">
        <table className="table">
          <tbody>
            <tr>
              <th>InstallerID</th>
              <th>Contacts</th>
              <th></th>
            </tr>
            {companies.map((co, i) => <tr key={i}>
              <td>{co.installerID}</td>
              <td>{co.count}</td>
              <td>
                <Link to={`/projects/${co.installerID}`} style={{ padding: '0.3em' }}><FaList size={20} /></Link>
                &nbsp;
                <Link to={`/installer-admin/${co.installerID}`}><FaUsers size={20} /></Link>
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </Section>
  </div>
}

export default SuperAdminPage